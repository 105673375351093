export const contactData = {
    "data": [
        {
            "id": 5,
            "attributes": {
                "Title": "Try ",
                "Description": "Click the button below to get started\n\n",
                "Url": "for free!",
                "Page": "Home,Company,Products,Blog,Tier,Contact,Demo,FAQ,Team",
                "createdAt": "2022-05-27T11:22:24.977Z",
                "updatedAt": "2022-07-15T08:21:51.508Z",
                "publishedAt": "2022-05-27T11:22:55.082Z",
                "Ordering": null,
                "Image": {
                    "data": [
                        {
                            "id": 2,
                            "attributes": {
                                "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Rectangle_316_6cb6ee2ad8.png",
                                "name": "Rectangle 316.png"
                            }
                        },
                        {
                            "id": 136,
                            "attributes": {
                                "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Microsoft_Teams_image_8_60849d7567.png",
                                "name": "MicrosoftTeams-image (8).png"
                            }
                        }
                    ]
                },
                "section_contents": {
                    "data": []
                }
            }
        },
        {
            "id": 24,
            "attributes": {
                "Title": "Contact Us",
                "Description": "",
                "Url": null,
                "Page": "Contact",
                "createdAt": "2022-06-13T08:37:12.409Z",
                "updatedAt": "2022-06-30T08:01:08.365Z",
                "publishedAt": "2022-06-13T08:37:16.016Z",
                "Ordering": "1",
                "Image": {
                    "data": null
                },
                "section_contents": {
                    "data": [
                        {
                            "id": 39,
                            "attributes": {
                                "Title": "Reach Out To Us",
                                "Url": null,
                                "Description": "contact@dhtech.io\n(510) 671-8300\n",
                                "ordering": 1,
                                "createdAt": "2022-06-13T08:39:47.020Z",
                                "updatedAt": "2022-07-04T04:38:16.615Z",
                                "publishedAt": "2022-06-13T08:39:51.730Z",
                                "SubDescription": "",
                                "SubTitle": "info@dnh.com",
                                "SectionImage": {
                                    "data": null
                                }
                            }
                        }
                    ]
                }
            }
        }
    ],
    "meta": {
        "pagination": {
            "page": 1,
            "pageSize": 25,
            "pageCount": 1,
            "total": 2
        }
    }
}