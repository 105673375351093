export const footerDataa = {
    "data": [
        {
            "id": 31,
            "attributes": {
                "Title": "Footer",
                "Description": "1028 SE Water Ave #275, Portland, OR 97214",
                "Url": "Kathmandu, Nepal",
                "Page": "Footer",
                "createdAt": "2022-06-27T08:37:35.440Z",
                "updatedAt": "2022-07-25T04:45:59.191Z",
                "publishedAt": "2022-06-27T09:09:01.378Z",
                "Ordering": null,
                "Image": {
                    "data": null
                },
                "section_contents": {
                    "data": [
                        {
                            "id": 73,
                            "attributes": {
                                "Title": "Facebook",
                                "Url": "www.facebook.com",
                                "Description": null,
                                "ordering": null,
                                "createdAt": "2022-06-27T08:39:24.796Z",
                                "updatedAt": "2022-06-27T08:39:30.555Z",
                                "publishedAt": "2022-06-27T08:39:30.483Z",
                                "SubDescription": null,
                                "SubTitle": null,
                                "SectionImage": {
                                    "data": {
                                        "id": 110,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/image_18_5e888e0e8d.jpg",
                                            "name": "image 18.jpg"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 74,
                            "attributes": {
                                "Title": "Instagram",
                                "Url": "www.instagram.com",
                                "Description": "",
                                "ordering": 2,
                                "createdAt": "2022-06-27T08:40:05.022Z",
                                "updatedAt": "2022-06-30T04:43:08.621Z",
                                "publishedAt": "2022-06-30T04:43:08.551Z",
                                "SubDescription": null,
                                "SubTitle": null,
                                "SectionImage": {
                                    "data": {
                                        "id": 111,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/image_19_095aded615.png",
                                            "name": "image 19.png"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 75,
                            "attributes": {
                                "Title": "LinkedIn",
                                "Url": null,
                                "Description": null,
                                "ordering": null,
                                "createdAt": "2022-06-27T08:43:39.715Z",
                                "updatedAt": "2022-06-27T09:08:43.495Z",
                                "publishedAt": "2022-06-27T09:08:43.420Z",
                                "SubDescription": null,
                                "SubTitle": null,
                                "SectionImage": {
                                    "data": {
                                        "id": 112,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/image_20_eb5cf2c310.jpg",
                                            "name": "image 20.jpg"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 80,
                            "attributes": {
                                "Title": "Contact Us",
                                "Url": "",
                                "Description": "contact@dhtech.io",
                                "ordering": 4,
                                "createdAt": "2022-07-01T09:48:35.017Z",
                                "updatedAt": "2022-07-04T04:36:25.827Z",
                                "publishedAt": "2022-07-01T09:49:03.413Z",
                                "SubDescription": null,
                                "SubTitle": "(510) 671-8300",
                                "SectionImage": {
                                    "data": null
                                }
                            }
                        },
                        {
                            "id": 81,
                            "attributes": {
                                "Title": "Copyright",
                                "Url": null,
                                "Description": "Copyright © 2022 D&H Technologies. All rights reserved.",
                                "ordering": null,
                                "createdAt": "2022-07-04T04:32:37.906Z",
                                "updatedAt": "2022-07-04T04:32:44.019Z",
                                "publishedAt": "2022-07-04T04:32:43.945Z",
                                "SubDescription": null,
                                "SubTitle": null,
                                "SectionImage": {
                                    "data": null
                                }
                            }
                        }
                    ]
                }
            }
        }
    ],
    "meta": {
        "pagination": {
            "page": 1,
            "pageSize": 25,
            "pageCount": 1,
            "total": 1
        }
    }
}