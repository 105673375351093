import React, { useEffect, useState } from "react";
import { homeData } from "../../json/homeData";
import Ai from "../Ai/Ai";
import Automation from "../Automation/Automation";
import CallToAction from "../CallToAction/CallToAction";
import CTA2 from "../CTA2/CTA2";
import Help from "../Help/Help";
import Hero from "../Hero/Hero";
import Page from "../Pages/Page";
import Why from "../Why/Why";
function Home() {
  const [loading, setLoading] = useState(true)
  const [heroData, setHeroData] = useState();
  const [callToAction, setCallToAction] = useState();
  const [why, setWhy] = useState();
  const [AI, setAI] = useState();
  const [automation, setAutomation] = useState();
  const [help, setHelp] = useState();
  const [cta2, setCta2] = useState();

  useEffect(() => {
    homeData?.data?.map((item, i) => {
      switch (item.id) {
        case 6:
          setHeroData(item);
          return;
        case 9:
          setCallToAction(item);
          return;
        case 1:
          setWhy(item);
          return;
        case 4:
          setAI(item);
          return;
        case 2:
          setAutomation(item);
          return;
        case 3:
          setHelp(item);
          return;
        case 5:
          setCta2(item);
          return;
        default:
          return;
      }
    });
  }, []);

  // if (loading) {
  //   return <Loader />;
  // }

  return (
    <Page title="Home">
      <Hero data={heroData} />
      <CallToAction data={callToAction} />
      <Why data={why} />
      <Ai data={AI} />
      <Automation data={automation} />
      <Help data={help} />
      <CTA2 data={cta2} />
    </Page>
  );
}

export default Home;
