import React, { useEffect, useState } from "react"
import ContactForm from "./ContactForm"

function ContactUs(props) {
  const [list1, setList1] = useState()
  const [list2, setList2] = useState()

  useEffect(() => {
    setList1(
      props?.data?.attributes?.section_contents?.data[0]?.attributes?.Description.split(
        "\n"
      )
    )
    setList2(
      props?.data?.attributes?.section_contents?.data[0]?.attributes?.Description.split(
        "\n"
      )
    )
  }, [props])

  return (
    <section className="section-contact-us">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-8">
            <ContactForm />
          </div>
          <div className="col-lg-3 offset-lg-1">
            <div className="content-container">
              {/* {
                props.data?.attributes?.section_contents?.data?.map((item, i) =>{
                  return(
                    <div className="item">
                    <h4 className="title">{item?.attributes?.Title}</h4>
                    <ul>
                      <li>info@dnh.com</li>
                      <li>T: 503-585-6242</li>
                      <li>F: 503-585-5543</li>
                    </ul>
                  </div>
                  )
                })
              } */}

              {/* {props?.data?.attributes?.section_contents?.data?.map(
                (item, i) => {
                  return (
                    <div className="item">
                      <h4 className="title">{item?.attributes?.Title}</h4>
                      {() =>
                        dispatch({
                          type: "setListData",
                          value: item.attributes?.Description,
                        })
                      }
                    </div>
                  )
                }
              )} */}

              <div className="item">
                <h4 className="title">
                  {
                    props?.data?.attributes?.section_contents?.data[0]
                      ?.attributes?.Title
                  }
                </h4>
                <ul>
                  {list1?.map((item, i) => {
                    if (i == 0) {
                      return (
                        <li key={i}>
                          <div style={{ marginRight: "20px" }}>
                            <svg
                              width="19"
                              height="15"
                              viewBox="0 0 19 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              stroke="#626262"
                            // className="no-fill"
                            >
                              <path
                                d="M0.75 1.25L9.5 7.5L18.25 1.25M0.75 13.75H18.25V1.25H0.75V13.75Z"
                                stroke-width="1.25"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                          {item}
                        </li>
                      )
                    }
                    if (i == 1) {
                      return (
                        <li key={i}>
                          <div style={{ marginRight: "20px" }}>
                            <svg
                              width="21"
                              height="21"
                              viewBox="0 0 21 21"
                              fill="#626262"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M6.71516 1.09534L5.89299 1.33841C5.16093 1.55507 4.50716 1.97245 4.00864 2.54142C3.51013 3.1104 3.18761 3.80729 3.07904 4.55007C2.73848 6.87723 3.49987 9.59826 5.33571 12.7188C7.16695 15.8314 9.18282 17.8367 11.4016 18.7145C12.1146 18.9964 12.895 19.0716 13.6502 18.9309C14.4053 18.7903 15.1035 18.4398 15.6616 17.9211L16.2831 17.3427C16.6866 16.9679 16.9378 16.462 16.9899 15.9191C17.042 15.3762 16.8915 14.8332 16.5663 14.391L15.0114 12.2754C14.8012 11.9898 14.5052 11.7759 14.1648 11.6637C13.8245 11.5515 13.4569 11.5466 13.1136 11.6497L10.7618 12.3553L10.701 12.3665C10.4419 12.4037 9.8433 11.8534 9.09796 10.5863C8.31822 9.26066 8.15769 8.48531 8.37211 8.28501L9.5681 7.19007C10.0055 6.78998 10.3042 6.26556 10.4225 5.69038C10.5407 5.1152 10.4725 4.51813 10.2274 3.98291L9.46834 2.32757C9.24008 1.82944 8.83502 1.42976 8.32869 1.20305C7.82235 0.976338 7.24928 0.938057 6.71631 1.09534H6.71516ZM8.42372 2.78895L9.18052 4.44429C9.32791 4.76527 9.36913 5.1235 9.29838 5.46865C9.22763 5.81379 9.04846 6.12851 8.78607 6.36859L7.58664 7.46465C6.81951 8.1781 7.07407 9.3957 8.10608 11.1489C9.07618 12.7987 9.96141 13.6134 10.9132 13.4716L11.0553 13.4423L13.4496 12.7255C13.5641 12.691 13.6866 12.6925 13.8002 12.7299C13.9137 12.7672 14.0124 12.8385 14.0826 12.9337L15.6375 15.0493C15.8003 15.2704 15.8757 15.542 15.8498 15.8135C15.8239 16.0851 15.6983 16.3382 15.4964 16.5257L14.8738 17.1041C14.4751 17.4744 13.9766 17.7246 13.4373 17.8249C12.898 17.9252 12.3407 17.8715 11.8316 17.6702C9.88458 16.9005 8.04416 15.0696 6.32988 12.1561C4.61101 9.2359 3.91497 6.75232 4.21425 4.70987C4.29173 4.1792 4.52208 3.68131 4.8782 3.2748C5.23432 2.8683 5.70138 2.57011 6.22438 2.41534L7.04655 2.17227C7.31311 2.09367 7.5997 2.11289 7.85287 2.22636C8.10605 2.33982 8.30854 2.53978 8.42257 2.78895H8.42372Z" />
                            </svg>
                          </div>
                          {item}
                        </li>
                      )
                    }
                    return <li key={i}>{item}</li>
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section >
  )
}

export default ContactUs
