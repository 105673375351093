import React from "react"
import HomeComponent from "../Components/Home/Home"
function Home() {

  // FETCH HOMEPAGE DATA
  // const appDispatch = useContext(DispatchContext)
  // useEffect(() => {
  //   const ourRequest = axios.CancelToken.source()
  //   async function fetch() {
  //     try {
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_BASE_URL}[$contains]=Home`,
  //         { cancelToken: ourRequest.token }
  //       )

  //       if (response.data) {
  //         appDispatch({ type: "FETCH_HOMEPAGE_SUCCESS", value: response.data })
  //       }
  //     } catch (e) {
  //       appDispatch({ type: "FETCH_HOMEPAGE_FAILURE" })
  //     }
  //   }
  //   fetch()
  //   return () => ourRequest.cancel()
  // }, [])

  return <HomeComponent />
}
export default Home
