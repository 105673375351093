import React, { useContext, useEffect, useState } from "react"

import { blogsData } from "../../../json/blogsData"
import StateContext from "../../../StateContext"
import Page from "../Page"
import BlogPosts from "./BlogPosts/BlogPosts"

function BlogsComponents() {
  const appState = useContext(StateContext)
  const [loading, setLoading] = useState(true)
  const [blogPosts, setblogPosts] = useState()
  const [cta2, setCta2] = useState()

  useEffect(() => {
    blogsData.data?.map((item, i) => {
      switch (item.id) {
        case 14:
          setblogPosts(item)
          return
        case 5:
          setCta2(item)
          return
        default:
          return
      }
    })
  }, [])

  // if (loading) {
  //   return <Loader />
  // }

  return (
    <main className="page-blogs">
      <Page title="Blogs">
        <BlogPosts data={blogPosts} />
      </Page>
    </main>
  )
}

export default BlogsComponents

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]
