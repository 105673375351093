import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
function Page(props) {
  const location = useLocation();

  useEffect(() => {
    document.title = `${props.title} Regulate`;
    window.scrollTo(0, 0);
  }, [props.title, location.pathname]);

  return <>{props.children}</>;
}

export default Page;
