import { isEmpty } from 'lodash'
import React, { useEffect, useState } from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import linkedInIcon from "../../../Assets/images/linkedIn-mini.png"
import { companyOurTeam } from "../../../json/companyOurTeam"
import CTA2 from "../../CTA2/CTA2"
import Page from "../Page"

function OurTeam() {
  const [loading, setLoading] = useState(true)
  const [name, setName] = useState(null)
  const [designation, setDesignation] = useState(null)
  const [bio, setBio] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [screenX, setScreenX] = useState(0)
  const [screenY, setScreenY] = useState(0)
  const [pageData, setPageData] = useState()
  const [entirePageData, setEntirePageData] = useState(companyOurTeam)
  const [cta2, setCta2] = useState()
  const history = useHistory()

  //FETCH TEAM DATA
  // useEffect(() => {
  //   const ourRequest = axios.CancelToken.source()
  //   async function fetch() {
  //     try {
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_BASE_URL}[$contains]=Team`,
  //         { cancelToken: ourRequest.token }
  //       )

  //       setEntirePageData(response.data)
  //       setLoading(false)
  //     } catch (e) {
  //     }
  //   }
  //   fetch()
  //   return () => ourRequest.cancel()
  // }, [])

  useEffect(() => {
    if (entirePageData) {
      entirePageData?.data?.map((item, i) => {
        switch (item.id) {
          case 5:
            setCta2(item)
            return
          case 29:
            setPageData(item)
            return
          default:
            return
        }
      })
    }
  }, [entirePageData])

  function handleShow(id, e) {
    setShowModal(true)
    setScreenX(e.screenX)
    setScreenY(e.screenY)
  }

  // if (loading) {
  //   return <Loader />
  // }

  return (
    <main className="page-ourTeam">
      <Page title="Our Team">
        <div className="container">
          <p className="page-navigation">
            <span
              onClick={() => history.push("/career")}
              style={{ cursor: "pointer" }}
            >
              Careers
            </span>{" "}
            <span>/ Our Team</span>
          </p>
        </div>
        <section className="section-teams">
          <div className="container">
            <div className="header-box text-center">
              <h1>Our Team</h1>
            </div>
            <div className="row gapup" >
              {pageData?.attributes?.section_contents?.data?.map((item, i) => {
                if (i < 2) {
                  return (
                    <OverlayTrigger
                      trigger="click"
                      placement="bottom"
                      key={item.id}
                      rootClose
                      overlay={
                        <Tooltip
                          style={{ marginTop: "1.5rem" }}
                          id={`tooltip-${item?.attributes?.id}`}
                          className="bio-box"
                        >
                          <div className="content-container">
                            <div className="left">
                              <div className="header-box">
                                <h2>{item?.attributes?.Title}</h2>
                                <h3>{item?.designation}</h3>
                              </div>
                              <div className="social-account">
                                <a href={isEmpty(item?.attributes?.Url) ? null : item?.attributes?.Url} target="_blank">
                                  <img src={linkedInIcon} alt="" />
                                  <h4>Linked In</h4>
                                </a>
                              </div>
                            </div>
                            <div className="right">
                              <div className="header-box">
                                <h3>About {item?.attributes?.Title}</h3>
                                <div className="text-box">
                                  <p>{item?.attributes?.SubDescription}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tooltip>
                      }
                    >
                      <div className="col-md-6" key={i}>
                        <div className="content-container">
                          <div className="image-container">
                            <img
                              src={
                                item.attributes?.SectionImage?.data?.attributes
                                  ?.url
                              }
                              alt=""
                            />
                          </div>
                          <div className="text-box">
                            <h3>{item?.attributes?.Title}</h3>
                            <h3 className="designation">
                              {item?.attributes?.SubTitle}
                            </h3>
                            <button>
                              <span
                                className="bio-trigger"
                                onClick={(e) => handleShow(item.id, e)}
                              >
                                <p>Read Bio</p>{" "}
                                <span className="icon-container"> {">"}</span>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </OverlayTrigger>
                  )
                }
              })}
            </div>
            <div className="row">
              {pageData?.attributes?.section_contents?.data?.map((item, i) => {
                if (i <= 2) {
                  return
                }
                return (
                  <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    key={item.id}
                    rootClose
                    overlay={
                      <Tooltip
                        style={{ marginTop: "1.5rem" }}
                        id={`tooltip-${item?.attributes?.id}`}
                        className="bio-box"
                      >
                        <div className="content-container">
                          <div className="left">
                            <div className="header-box">
                              <h2>{item?.attributes?.Title}</h2>
                              <h3>{item?.designation}</h3>
                            </div>
                            <div className="social-account">
                              <a href={isEmpty(item?.attributes?.Url) ? null : item?.attributes?.Url} target="_blank">
                                <img src={linkedInIcon} alt="" />
                                <h4>Linked In</h4>
                              </a>
                            </div>
                          </div>
                          <div className="right">
                            <div className="header-box">
                              <h3>About {item?.attributes?.Title}</h3>
                              <div className="text-box">
                                <p>{item?.attributes?.SubDescription}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tooltip>
                    }
                  >
                    <div className="col-md-6 col-lg-4" key={i}>
                      <div className="content-container">
                        <div className="image-container">
                          <img
                            src={
                              item.attributes?.SectionImage?.data?.attributes
                                ?.url
                            }
                            alt=""
                          />
                        </div>
                        <div className="text-box">
                          <h3>{item?.attributes?.Title}</h3>
                          <h3 className="designation">
                            {item?.attributes?.SubTitle}
                          </h3>
                          <button>
                            <span
                              className="bio-trigger"
                              onClick={(e) => handleShow(item.id, e)}
                            >
                              <p>Read Bio</p>{" "}
                              <span className="icon-container"> {">"}</span>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </OverlayTrigger>
                )
              })}
            </div>
          </div>
        </section>

        <CTA2 data={cta2} />
      </Page>
    </main>
  )
}

export default OurTeam
