export const companyData = {
    "data": [
        {
            "id": 5,
            "attributes": {
                "Title": "Try ",
                "Description": "Click the button below to get started\n\n",
                "Url": "for free!",
                "Page": "Home,Company,Products,Blog,Tier,Contact,Demo,FAQ,Team",
                "createdAt": "2022-05-27T11:22:24.977Z",
                "updatedAt": "2022-07-15T08:21:51.508Z",
                "publishedAt": "2022-05-27T11:22:55.082Z",
                "Ordering": null,
                "Image": {
                    "data": [
                        {
                            "id": 2,
                            "attributes": {
                                "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Rectangle_316_6cb6ee2ad8.png",
                                "name": "Rectangle 316.png"
                            }
                        },
                        {
                            "id": 136,
                            "attributes": {
                                "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Microsoft_Teams_image_8_60849d7567.png",
                                "name": "MicrosoftTeams-image (8).png"
                            }
                        }
                    ]
                },
                "section_contents": {
                    "data": []
                }
            }
        },
        {
            "id": 7,
            "attributes": {
                "Title": "About D&H",
                "Description": "At D&H, we believe in disrupting the status quo. We believe in empowering teams to achieve their greatest potential. We do this by eliminating the tedium of regulatory compliance.\n",
                "Url": null,
                "Page": "Company",
                "createdAt": "2022-05-27T12:26:02.974Z",
                "updatedAt": "2022-06-25T01:15:04.819Z",
                "publishedAt": "2022-05-27T12:26:29.699Z",
                "Ordering": "1",
                "Image": {
                    "data": [
                        {
                            "id": 19,
                            "attributes": {
                                "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/hero2_2e558bc61d.jpg",
                                "name": "hero2.jpg"
                            }
                        }
                    ]
                },
                "section_contents": {
                    "data": []
                }
            }
        },
        {
            "id": 8,
            "attributes": {
                "Title": "Who We Are",
                "Description": "D&H uses Natural Language Processing (NLP) architecture to harness the power of Artificial Intelligence. Our algorithms are built upon the body of knowledge from 1,000’s scientists resulting in a more accurate and more consistent outcome every time. Because NLP powers our platform, we include our scientific peers from across the globe, regardless of language. With our global community of world-class thought leaders, we build robust systems allowing Machine Learning and Neural Networks to accel. We automate mundane tasks to elevate the quality of work.\n",
                "Url": null,
                "Page": "Company",
                "createdAt": "2022-05-27T12:28:00.245Z",
                "updatedAt": "2022-06-25T01:15:41.003Z",
                "publishedAt": "2022-05-27T12:28:13.630Z",
                "Ordering": "2",
                "Image": {
                    "data": [
                        {
                            "id": 3,
                            "attributes": {
                                "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Rectangle_8_e7b57e352a.png",
                                "name": "Rectangle 8.png"
                            }
                        }
                    ]
                },
                "section_contents": {
                    "data": []
                }
            }
        },
        {
            "id": 10,
            "attributes": {
                "Title": "Our Expertise",
                "Description": null,
                "Url": null,
                "Page": "Company",
                "createdAt": "2022-06-09T06:02:34.769Z",
                "updatedAt": "2022-06-09T06:55:17.783Z",
                "publishedAt": "2022-06-09T06:55:17.716Z",
                "Ordering": "3",
                "Image": {
                    "data": null
                },
                "section_contents": {
                    "data": [
                        {
                            "id": 6,
                            "attributes": {
                                "Title": "Dedicated To Compliance",
                                "Url": null,
                                "Description": "Compliance: the assurance that your organization follows industry standards and government regulations. Integrating compliance into every facet of routine actions of all stakeholders ensures that risks are appropriately mitigated, compliance documentation is standard, and governance is embedded in your organization’s culture. At D&H, we integrate best practices with local governmental regulations; governance, risk management, and compliance are no longer a siloed function in your organization. The result of working with D&H is improvements: in organizational performance, quality of products and services, and avoiding unnecessary risks that have economic and reputational consequences.\n \n",
                                "ordering": 1,
                                "createdAt": "2022-06-09T06:03:38.925Z",
                                "updatedAt": "2022-06-25T01:11:51.783Z",
                                "publishedAt": "2022-06-09T08:45:01.643Z",
                                "SubDescription": null,
                                "SubTitle": null,
                                "SectionImage": {
                                    "data": {
                                        "id": 13,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Rectangle_8_1_0d874c1642.png",
                                            "name": "Rectangle 8 (1).png"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 7,
                            "attributes": {
                                "Title": "Automation",
                                "Url": null,
                                "Description": "Wherever possible we automate.  Automation reduces inconsistencies in how different individuals may perceive differences from the same information.  We automate basic data entry significantly, relying instead upon supplier-provided information whenever possible.  The data is scraped, auto-input, and classified.  Our classification systems are central to our data processing. By creating clusters of information, we analyze and evaluate what groups of information exist and the shared commonalities.  Different information groups may have more than one commonality with other seemingly different groups.  In the analysis of commonalities, we apply logic rules, regulations, or best practices which result in consistent reproducible outcomes revealing risks to be controlled and pathways for optimization.\n",
                                "ordering": 2,
                                "createdAt": "2022-06-09T06:53:04.819Z",
                                "updatedAt": "2022-06-25T01:13:07.134Z",
                                "publishedAt": "2022-06-09T06:53:08.108Z",
                                "SubDescription": null,
                                "SubTitle": null,
                                "SectionImage": {
                                    "data": {
                                        "id": 14,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/expertise2_83239d3746.jpg",
                                            "name": "expertise2.jpg"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 8,
                            "attributes": {
                                "Title": "Food Technology",
                                "Url": null,
                                "Description": "Food Science in its simplest terms is the mitigation of food-borne illness through the preservation of food.  While humans have been preserving food for centuries, little was known about why food preservation processes worked.  Unknowingly, Napoleon helped to invent canning a major development in food preservation in 1809.  Over the next 150 years, progress was made in the development of food processing and the application of technology for the preservation of food.  True acceleration of improved quality, processing techniques, and novel methodologies wasn’t achieved until the development of HACCP in 1960.  The multitude of food products available to consumers today is a direct result of HACCP.  HACCP principles are embedded in every D&H product. D&H platforms embed preventive controls and optimization systems making our platforms leading-edge next-generation systems.  The next stage of product evolution is on our doorstep.\n \nModern Food Science requires food safety as a foundation but elevates these food expressions to deliver on many other key attributes: taste, color, freshness, affordability, health benefits, absence of allergens, nutrition claims, reasons to believe e.g. Fair Trade®, Ecological, Sustainability, etc.  Navigating all the possible attributes, ensuring food safety, validating desired claims, and delivering on product economics makes product differentiation increasingly more difficult, cumbersome and time-consuming.  D&H focuses on the needs of the industry and develops solutions that meet the continuing pressures of consumer requirements and the dictates of regulators while delivering a cost-effective final product at an accelerated pace.\n",
                                "ordering": 3,
                                "createdAt": "2022-06-09T06:53:59.134Z",
                                "updatedAt": "2022-06-25T01:22:15.169Z",
                                "publishedAt": "2022-06-09T06:54:21.004Z",
                                "SubDescription": null,
                                "SubTitle": null,
                                "SectionImage": {
                                    "data": {
                                        "id": 15,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/expertise3_8881a4d23f.jpg",
                                            "name": "expertise3.jpg"
                                        }
                                    }
                                }
                            }
                        }
                    ]
                }
            }
        },
        {
            "id": 11,
            "attributes": {
                "Title": "Get To Know Us",
                "Description": null,
                "Url": null,
                "Page": "Company",
                "createdAt": "2022-06-09T06:56:18.274Z",
                "updatedAt": "2022-06-24T07:52:33.477Z",
                "publishedAt": "2022-06-09T06:56:21.067Z",
                "Ordering": "4",
                "Image": {
                    "data": null
                },
                "section_contents": {
                    "data": [
                        {
                            "id": 10,
                            "attributes": {
                                "Title": "Careers",
                                "Url": "/career",
                                "Description": "Check out our career opportunities. ",
                                "ordering": 1,
                                "createdAt": "2022-06-09T07:02:11.141Z",
                                "updatedAt": "2023-08-01T07:52:05.005Z",
                                "publishedAt": "2023-08-01T07:52:04.941Z",
                                "SubDescription": null,
                                "SubTitle": "Join Us",
                                "SectionImage": {
                                    "data": {
                                        "id": 17,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/careers_5598de541d.svg",
                                            "name": "careers.svg"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 11,
                            "attributes": {
                                "Title": "Contact",
                                "Url": "/contact",
                                "Description": "Have questions? Contact us by clicking the button below. ",
                                "ordering": 2,
                                "createdAt": "2022-06-09T07:03:50.705Z",
                                "updatedAt": "2022-06-24T07:54:45.448Z",
                                "publishedAt": "2022-06-09T07:03:54.039Z",
                                "SubDescription": null,
                                "SubTitle": "Get In Touch",
                                "SectionImage": {
                                    "data": {
                                        "id": 18,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/contact_38e327219e.svg",
                                            "name": "contact.svg"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 9,
                            "attributes": {
                                "Title": "Teams",
                                "Url": "/company/ourTeam",
                                "Description": "Meet the brains behind D&H. ",
                                "ordering": 3,
                                "createdAt": "2022-06-09T07:00:28.732Z",
                                "updatedAt": "2022-06-24T07:55:55.654Z",
                                "publishedAt": "2022-06-09T07:01:29.361Z",
                                "SubDescription": null,
                                "SubTitle": "Meet Our Team",
                                "SectionImage": {
                                    "data": {
                                        "id": 16,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/teams_f484070c40.svg",
                                            "name": "teams.svg"
                                        }
                                    }
                                }
                            }
                        }
                    ]
                }
            }
        }
    ],
    "meta": {
        "pagination": {
            "page": 1,
            "pageSize": 25,
            "pageCount": 1,
            "total": 5
        }
    }
}