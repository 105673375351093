export const careerData = {
    "data": [
        {
            "id": 17,
            "attributes": {
                "Title": "Explore new avenues at D&H",
                "Description": null,
                "Url": null,
                "Page": "Career",
                "createdAt": "2022-06-13T07:03:04.771Z",
                "updatedAt": "2022-07-14T07:22:59.519Z",
                "publishedAt": "2022-06-13T07:08:04.116Z",
                "Ordering": "1",
                "Image": {
                    "data": [
                        {
                            "id": 47,
                            "attributes": {
                                "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Group_630_dc5e4f9b0e.jpg",
                                "name": "Group 630.jpg"
                            }
                        }
                    ]
                },
                "section_contents": {
                    "data": []
                }
            }
        },
        {
            "id": 18,
            "attributes": {
                "Title": "Be a part of D&H",
                "Description": "At D&H we are pioneers.  We are business savvy, connecting our mission to real-world challenges and delivering solutions that improve the human condition.\n",
                "Url": null,
                "Page": "Career",
                "createdAt": "2022-06-13T07:11:54.281Z",
                "updatedAt": "2022-06-25T00:55:00.900Z",
                "publishedAt": "2022-06-13T07:11:59.232Z",
                "Ordering": "2",
                "Image": {
                    "data": [
                        {
                            "id": 48,
                            "attributes": {
                                "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Rectangle_8_c1181f3921.jpg",
                                "name": "Rectangle 8.jpg"
                            }
                        }
                    ]
                },
                "section_contents": {
                    "data": []
                }
            }
        },
        {
            "id": 19,
            "attributes": {
                "Title": "Life At D&H",
                "Description": "The Helmsman Group is a distinguished food consulting company that has been around for nearly 25 years. Over the years, the Helmsman Group has been a key player in a variety of opportunities in the CPG industry ranging from product development, commercialization, and business development. The Helmsman Group has been recognized as a company that provides strong direction and guidance for their clients but has also fosters an energetic internal work culture, emphasizing the importance of food at its core. At the Helmsman Group, team members are encouraged to explore new ideas, to be creative in solving problems, and to create delicious food! ",
                "Url": null,
                "Page": "Career",
                "createdAt": "2022-06-13T07:15:16.956Z",
                "updatedAt": "2023-02-15T06:34:54.528Z",
                "publishedAt": "2022-06-13T07:15:21.899Z",
                "Ordering": "3",
                "Image": {
                    "data": null
                },
                "section_contents": {
                    "data": [
                        {
                            "id": 67,
                            "attributes": {
                                "Title": "Gopal Rakhal Subedi",
                                "Url": null,
                                "Description": "I am proud to say I have been here since the beginning; the company has been growing and evolving all this time. The work at Dhuni can be fascinating, the culture we have built and are continuously building is what separates us from other. It’s a work culture that welcomes flexibility, differences in thought and perspective and makes ease at work. Teams are collaborative and we always support each other to achieve our goals",
                                "ordering": 3,
                                "createdAt": "2022-06-27T07:11:50.726Z",
                                "updatedAt": "2023-02-15T06:42:52.709Z",
                                "publishedAt": "2022-06-27T07:11:53.995Z",
                                "SubDescription": null,
                                "SubTitle": "Teach Lead , D&H",
                                "SectionImage": {
                                    "data": {
                                        "id": 90,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/photostudio_5_designify_2_6d9ab9005a.png",
                                            "name": "Gopal..png"
                                        }
                                    }
                                }
                            }
                        }
                    ]
                }
            }
        },
        {
            "id": 20,
            "attributes": {
                "Title": "Locations",
                "Description": "",
                "Url": null,
                "Page": "Career",
                "createdAt": "2022-06-13T07:26:03.095Z",
                "updatedAt": "2022-06-24T10:59:37.058Z",
                "publishedAt": "2022-06-13T07:26:24.533Z",
                "Ordering": "4",
                "Image": {
                    "data": null
                },
                "section_contents": {
                    "data": [
                        {
                            "id": 62,
                            "attributes": {
                                "Title": "Locations",
                                "Url": null,
                                "Description": "",
                                "ordering": 1,
                                "createdAt": "2022-06-24T06:44:25.409Z",
                                "updatedAt": "2022-07-14T07:24:29.340Z",
                                "publishedAt": "2022-06-24T06:44:57.767Z",
                                "SubDescription": "- San Francisco, USA\n- Portland, USA\n- Kathmandu, Nepal",
                                "SubTitle": "We have multiple locations globally.",
                                "SectionImage": {
                                    "data": {
                                        "id": 50,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Rectangle_8_1_f6f53c1463.jpg",
                                            "name": "Rectangle 8 (1).jpg"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 63,
                            "attributes": {
                                "Title": "Teams",
                                "Url": null,
                                "Description": "We innovate the future.",
                                "ordering": 2,
                                "createdAt": "2022-06-24T06:49:10.352Z",
                                "updatedAt": "2022-07-04T01:20:10.413Z",
                                "publishedAt": "2022-06-24T06:49:14.271Z",
                                "SubDescription": "We innovate the future.",
                                "SubTitle": null,
                                "SectionImage": {
                                    "data": {
                                        "id": 51,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Rectangle_8_2_71dd92113a.jpg",
                                            "name": "Rectangle 8 (2).jpg"
                                        }
                                    }
                                }
                            }
                        }
                    ]
                }
            }
        },
        {
            "id": 22,
            "attributes": {
                "Title": "Connect with us",
                "Description": "",
                "Url": null,
                "Page": "Career",
                "createdAt": "2022-06-13T07:38:14.915Z",
                "updatedAt": "2022-08-04T03:13:18.003Z",
                "publishedAt": "2022-06-13T07:38:22.047Z",
                "Ordering": "6",
                "Image": {
                    "data": [
                        {
                            "id": 95,
                            "attributes": {
                                "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Vector_dd74fa8fc5.png",
                                "name": "Vector.png"
                            }
                        },
                        {
                            "id": 52,
                            "attributes": {
                                "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Rectangle_317_f4ebcdc9c3.png",
                                "name": "Rectangle 317.png"
                            }
                        }
                    ]
                },
                "section_contents": {
                    "data": []
                }
            }
        }
    ],
    "meta": {
        "pagination": {
            "page": 1,
            "pageSize": 25,
            "pageCount": 1,
            "total": 5
        }
    }
}