export const companyOurTeam = {
    "data": [
        {
            "id": 5,
            "attributes": {
                "Title": "Try ",
                "Description": "Click the button below to get started\n\n",
                "Url": "for free!",
                "Page": "Home,Company,Products,Blog,Tier,Contact,Demo,FAQ,Team",
                "createdAt": "2022-05-27T11:22:24.977Z",
                "updatedAt": "2022-07-15T08:21:51.508Z",
                "publishedAt": "2022-05-27T11:22:55.082Z",
                "Ordering": null,
                "Image": {
                    "data": [
                        {
                            "id": 2,
                            "attributes": {
                                "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Rectangle_316_6cb6ee2ad8.png",
                                "name": "Rectangle 316.png"
                            }
                        },
                        {
                            "id": 136,
                            "attributes": {
                                "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Microsoft_Teams_image_8_60849d7567.png",
                                "name": "MicrosoftTeams-image (8).png"
                            }
                        }
                    ]
                },
                "section_contents": {
                    "data": []
                }
            }
        },
        {
            "id": 29,
            "attributes": {
                "Title": "Our Team",
                "Description": null,
                "Url": null,
                "Page": "Team",
                "createdAt": "2022-06-17T08:29:17.541Z",
                "updatedAt": "2023-07-31T08:37:33.304Z",
                "publishedAt": "2022-06-17T08:44:05.644Z",
                "Ordering": null,
                "Image": {
                    "data": null
                },
                "section_contents": {
                    "data": [
                        {
                            "id": 50,
                            "attributes": {
                                "Title": "Mark Haas",
                                "Url": "",
                                "Description": null,
                                "ordering": 1,
                                "createdAt": "2022-06-17T08:33:09.803Z",
                                "updatedAt": "2022-07-22T05:15:54.397Z",
                                "publishedAt": "2022-06-17T08:33:12.964Z",
                                "SubDescription": "Mark Haas has been helping natural and organic companies grow for nearly 30 years. With a background in leading entrepreneurial high-growth brands, executive management within multinational corporations and founding his own contract manufacturing business; Mark possesses a unique perspective that blends entrepreneurial passion and disciplined experience into power-play strategies that help brands achieve their full potential. At The Helmsman Group, Mark and his team help accelerate high-potential food and beverage brands by providing this strategic consultative approach to Innovation and new product development.  D&H Technologies, Inc. further leverages these strengths into regulatory, compliance and new product development automation.\n\nAs an investor and advisor, Mark supports organizations that empower the global organic and natural foods industry. Mark is committed to nurturing the next generation of food industry leaders through his work with Christie & Co’s Gang of Good, Silverwood Partners as well as the Education Research Institute, a non-profit affiliate of Food Northwest.\n",
                                "SubTitle": "CEO",
                                "SectionImage": {
                                    "data": {
                                        "id": 154,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/mark_edited_912f855fa2.png",
                                            "name": "mark_edited.png"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 51,
                            "attributes": {
                                "Title": "Ishan Mani Subedi",
                                "Url": null,
                                "Description": null,
                                "ordering": 2,
                                "createdAt": "2022-06-17T08:37:05.154Z",
                                "updatedAt": "2022-06-28T05:56:26.614Z",
                                "publishedAt": "2022-06-17T08:37:08.458Z",
                                "SubDescription": "Ishan brings a decade of startup experience building software products to D&H. Having worked in multiple startups he has managed to grow the engineering, manage technical debt, architect for scale, and improve the revenue process. Ishan has a strong background in Physics, Mathematics, and Software engineering and has managed to create and lead a strong natural language processing group at D&H producing peer-reviewed publications at International Venues. His area of research is in requirement engineering, transfer learning, text augmentation, and image processing. At D&H, Ishan has managed to establish a high-performing team geared towards innovation, growth, and development.",
                                "SubTitle": "CTO",
                                "SectionImage": {
                                    "data": {
                                        "id": 97,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Rectangle_306_36548891a4.png",
                                            "name": "Rectangle 306.png"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 52,
                            "attributes": {
                                "Title": "Alison Hall",
                                "Url": null,
                                "Description": null,
                                "ordering": 3,
                                "createdAt": "2022-06-17T08:37:30.719Z",
                                "updatedAt": "2022-07-20T22:23:14.087Z",
                                "publishedAt": "2022-06-17T08:37:33.652Z",
                                "SubDescription": "Alison Hall is the Senior Product Development Manager at D&H. Alison has a culinary background as a graduate of Le Cordon Bleu. She has spent the past sixteen years focusing on product development, commercialization, and client management across the retail, food service and club channels. ",
                                "SubTitle": "Senior Product Development Manager",
                                "SectionImage": {
                                    "data": {
                                        "id": 49,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Rectangle_8_e110681d6d.png",
                                            "name": "Rectangle 8.png"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 53,
                            "attributes": {
                                "Title": "Brian Luevano",
                                "Url": "",
                                "Description": null,
                                "ordering": 4,
                                "createdAt": "2022-06-17T08:37:41.890Z",
                                "updatedAt": "2022-07-22T10:57:18.499Z",
                                "publishedAt": "2022-06-17T08:37:44.792Z",
                                "SubDescription": "Brian Luevano is a Food Tech Project Manager at D&H. He has over a decade of food manufacturing experience in quality, R & D, project management, and commercialization. Over the years, he has launched over 500 new products in product categories such as burritos, entrees, dressings, dips, and salads. Prior to the Helmsman Group, Brian worked at Reser’s Fine Foods leading Commercialization projects for all the facilities on the West Coast.",
                                "SubTitle": " Project Manager",
                                "SectionImage": {
                                    "data": {
                                        "id": 98,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/brian_L_7bb3636ac9.png",
                                            "name": "brian L.png"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 54,
                            "attributes": {
                                "Title": "Brian Shimada",
                                "Url": "",
                                "Description": null,
                                "ordering": 5,
                                "createdAt": "2022-06-17T08:37:59.121Z",
                                "updatedAt": "2022-07-22T10:57:32.448Z",
                                "publishedAt": "2022-06-17T08:38:02.301Z",
                                "SubDescription": "Brian Shimada is a Product Developer at D&H. He graduated from the University of California, Davis with a B.S. in Food Science. Brian has been in the food industry for over a decade, specializing in seasoning blends and snack foods. He has worked on a variety of projects, ranging from tortilla chips to plant-based products to popcorn and is driven towards optimizing functional food systems. ",
                                "SubTitle": "Product Developer",
                                "SectionImage": {
                                    "data": {
                                        "id": 108,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Brian_shimada_0facf47a7e.png",
                                            "name": "Brian shimada.png"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 56,
                            "attributes": {
                                "Title": "Gopal Rakhal Subedi",
                                "Url": "https://www.linkedin.com/in/gopal-rakhal-subedi-82453a5b/",
                                "Description": "",
                                "ordering": 7,
                                "createdAt": "2022-06-17T08:39:02.765Z",
                                "updatedAt": "2022-07-01T10:03:52.838Z",
                                "publishedAt": "2022-06-17T08:39:06.308Z",
                                "SubDescription": "Gopal brings more than 12 years of software development and management experience to D&H. He has written many internal frameworks and is involved in setting development protocols that aid in blazing fast and quality software development. His go-to tech stack is .NET and MS SQL. Recently he has also been actively involved in the NLP research at D&H Technologies",
                                "SubTitle": "Technical Lead",
                                "SectionImage": {
                                    "data": {
                                        "id": 144,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Gopal_BW_removebg_preview_51305f6a64.jpg",
                                            "name": "Gopal_BW-removebg-preview.jpg"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 58,
                            "attributes": {
                                "Title": "Matt Oakley",
                                "Url": "",
                                "Description": "",
                                "ordering": 9,
                                "createdAt": "2022-06-17T08:40:30.509Z",
                                "updatedAt": "2022-07-22T10:58:13.865Z",
                                "publishedAt": "2022-06-17T08:40:33.744Z",
                                "SubDescription": "Matt Oakley is a Research and Development Manager at D&H. He obtained a Bachelor of Science in Food Science & Technology from Oregon State University specializing in Fermentation Science. Matt has a decade of food industry experience specializing in the beverage sector including work with natural beverage solutions and fermented beverages.\n",
                                "SubTitle": "Product Developer",
                                "SectionImage": {
                                    "data": {
                                        "id": 103,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/matt_8bd68bd5bd.png",
                                            "name": "matt.png"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 70,
                            "attributes": {
                                "Title": "Shailaza Dhakal",
                                "Url": "https://www.linkedin.com/in/shailaza-dhakal/",
                                "Description": null,
                                "ordering": 13,
                                "createdAt": "2022-06-27T07:48:33.284Z",
                                "updatedAt": "2022-08-16T04:41:40.781Z",
                                "publishedAt": "2022-06-27T07:51:06.894Z",
                                "SubDescription": "Shailaza is a Technical Project manager at D&H. She has more than a decade of experience in Project Management and Operations. She has helped many startups scale from 0 dollars to revenue in a relatively short amount of time. Shailaza has successfully launched many software products spanning multiple domains such as pharmaceuticals, food technology, transportation, and finance.",
                                "SubTitle": "Technical Project Manager",
                                "SectionImage": {
                                    "data": {
                                        "id": 113,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Microsoft_Teams_image_6_bd2d569f0f.png",
                                            "name": "MicrosoftTeams-image (6).png"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 71,
                            "attributes": {
                                "Title": " Shawnna Haas",
                                "Url": null,
                                "Description": null,
                                "ordering": 14,
                                "createdAt": "2022-06-27T07:50:03.508Z",
                                "updatedAt": "2022-07-20T22:17:46.719Z",
                                "publishedAt": "2022-06-27T07:50:58.270Z",
                                "SubDescription": "Shawnna Haas is a seasoned administrative assistant with over 20 years of experience in the field.  With a strong focus on customer service, Shawnna has held positions in the financial, medical, and business fields. Prior to joining the D&H, Shawnna worked at John Muir Hospital where she was recognized for her outstanding service. \n",
                                "SubTitle": "Executive Assistant",
                                "SectionImage": {
                                    "data": {
                                        "id": 106,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/shawna_e91b93a237.png",
                                            "name": "shawna.png"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 72,
                            "attributes": {
                                "Title": "Sujan Odari",
                                "Url": "https://www.linkedin.com/in/sujan-odari-532ba9152/",
                                "Description": null,
                                "ordering": null,
                                "createdAt": "2022-06-27T07:52:33.403Z",
                                "updatedAt": "2022-06-28T06:07:22.492Z",
                                "publishedAt": "2022-06-27T07:52:37.133Z",
                                "SubDescription": "Sujan Odari is a Software Engineer at D&H . He has over a half-decade of software engineering experience. He has worked on different software products in product categories such as transport management systems and real-estate applications. Sujan is an adept javascript programmer and is responsible for creating elegant and user-friendly interfaces.",
                                "SubTitle": "Senior Software Engineer",
                                "SectionImage": {
                                    "data": {
                                        "id": 107,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Sujanphoto_4b397f22be.jpeg",
                                            "name": "Sujanphoto.jpeg"
                                        }
                                    }
                                }
                            }
                        }
                    ]
                }
            }
        }
    ],
    "meta": {
        "pagination": {
            "page": 1,
            "pageSize": 25,
            "pageCount": 1,
            "total": 2
        }
    }
}