import axios from 'axios'
import React, { useContext, useEffect, useState } from "react"
import ReactMarkdown from "react-markdown"
import { useHistory, useLocation, useParams } from "react-router-dom"
import StateContext from "../../../StateContext"
import CTA2 from "../../CTA2/CTA2"
import Page from "../Page"
import Applyform from "./Applyform"


function CareerDetailsComponent() {
  const appState = useContext(StateContext)
  const [jobData, setJobData] = useState([])
  const [loading, setLoading] = useState(true)
  const [showForm, setShowForm] = useState(false)
  const [responsibilities, setResponsibilities] = useState([])
  const [cta2, setCta2] = useState()
  const location = useLocation()
  const history = useHistory()
  const { id } = useParams()

  // const setShowForm1 = () => {
  //   history.push(`/career`, { from: 'Career Detail Page' });
  //   location.navigate('/career')
  // }

  useEffect(() => {
    axios.get('https://dnh-backend-website.herokuapp.com/api/job-posts').then((res) =>
      setJobData(res?.data?.data?.filter((el) => el.id == id))
    )

    axios.get(`${process.env.REACT_APP_BASE_URL}[$contains]=Home`).then((ress) =>
      ress?.data?.data?.map((item, i) => {
        switch (item.id) {
          case 5:
            setCta2(item)
            return
          default:
            return
        }
      })
    )
  }, [appState, location.pathname, id])

  useEffect(() => {
    if (jobData?.length) {
      setResponsibilities(jobData[0]?.attributes.Responsibilites.split("-"))
    }
  }, [jobData])

  if (!jobData?.length) {
    return <h1>Lloading</h1>
  }

  return (
    <main className="career-details-page">
      <Page title="Career Details">
        <div className="container">
          <p className="page-navigation">
            <span
              onClick={() => history.push("/career")}
              style={{ cursor: "pointer" }}
            >
              Careers
            </span>{" "}
            <span>/ Job Details</span>
          </p>
        </div>
        <section className="section-career-detail">
          <div className="container">
            <div className="content-container">
              <div className="header-container">
                <p className="text-muted">January 05, 2019</p>
                <div className="header-box">
                  <h1>{jobData[0]?.attributes?.Title}</h1>
                </div>
                <p className="subitle">{jobData[0]?.attributes?.Location}</p>
                <div className="button-container">
                  <span className="overlay"></span>
                  <button
                    className="btn btn-primary"
                    onClick={() => setShowForm(true)}
                  >
                    Apply Job
                  </button>
                </div>
              </div>
              <div className="body-container">
                <div className="header-box">
                  <h3>Job Description</h3>
                </div>
                <div className="text-box">
                  <p className="subtitle">
                    {jobData[0]?.attributes?.JobDescription}
                  </p>
                </div>
                <div className="header-box">
                  <h3>Roles/Responsibilities</h3>
                </div>
                <div className="text-box">
                  {/* {jobData[0]?.attributes?.Responsibilities.split("-").map(
                    (item, i) => {
                    }
                  )} */}
                  <ul>
                    {responsibilities?.map((item, i) => {
                      if (i > 0) {
                        return (
                          <li>
                            <p className={window.innerWidth < 700 ? "subtitlemobile" : "subtitle"} key={i}>
                              <ReactMarkdown>
                                {item}
                              </ReactMarkdown>
                            </p>
                          </li>
                        )
                      }
                    })}
                  </ul>
                </div>
                <div className="header-box">
                  <h3>Basic Qualifications</h3>
                </div>
                <div className="text-box">
                  <div className="row">
                    <div className="col-3">
                      <p className="subtitle text-bold highlight-primary" >
                        Experience :
                      </p>
                    </div>
                    <div className="col-9">
                      <p className={window.innerWidth < 700 ? "subtitlemobile1" : "subtitle text-bold"}>
                        {" "}
                        <ReactMarkdown>
                          {jobData[0]?.attributes?.Experience}
                        </ReactMarkdown>
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3">
                      <p className="subtitle text-bold highlight-primary">
                        Level :
                      </p>
                    </div>
                    <div className="col-9">
                      <p className="subtitle text-bold">
                        {" "}
                        {jobData[0]?.attributes?.Level}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3">
                      <p className="subtitle text-bold highlight-primary">
                        Education :
                      </p>
                    </div>
                    <div className="col-9">
                      <p className="subtitle text-bold">
                        {" "}
                        {jobData[0]?.attributes?.Education}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3">
                      <p className="subtitle text-bold highlight-primary">
                        Location :
                      </p>
                    </div>
                    <div className="col-9">
                      <p className="subtitle text-bold">
                        {" "}
                        {jobData[0]?.attributes?.Location}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="button-container">
                  <span className="overlay"></span>
                  <button
                    className="btn btn-primary"
                    onClick={() => setShowForm(true)}
                  >
                    Apply Job
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <CTA2 data={cta2} />
        <Applyform
          showForm={showForm}
          setShowForm={(val) => setShowForm(val)}
        />
      </Page>
    </main>
  )
}

export default CareerDetailsComponent
