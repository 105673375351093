import React, { useContext, useEffect, useState } from "react"
import { faqData } from "../../../json/faqdata"
import StateContext from "../../../StateContext"
import CTA2 from "../../CTA2/CTA2"
import Hero from "../FAQ/Hero/Hero"
import Page from "../Page"
import SecAccordion from "./Accordion/SecAccordion"
function FAQComponent() {
  const appState = useContext(StateContext)
  const [loading, setLoading] = useState(true)
  const [hero, setHero] = useState()
  const [secAccordion, setSecAccordion] = useState()
  const [cta2, setCta2] = useState()
  useEffect(() => {
    faqData.data?.map((item, i) => {
      switch (item.id) {
        case 26:
          setHero(item)
          return
        case 27:
          setSecAccordion(item)
          return
        case 5:
          setCta2(item)
          return
        default:
          return
      }
    })
  }, [])

  // if (loading) {
  //   return <Loader />
  // }
  return (
    <main className="page-faq">
      <Page title="FAQ">
        <Hero data={hero} />
        <SecAccordion data={secAccordion} />
        <CTA2 data={cta2} />
      </Page>
    </main>
  )
}

export default FAQComponent
