export const productData = {
    "data": [
        {
            "id": 5,
            "attributes": {
                "Title": "Try ",
                "Description": "Click the button below to get started\n\n",
                "Url": "for free!",
                "Page": "Home,Company,Products,Blog,Tier,Contact,Demo,FAQ,Team",
                "createdAt": "2022-05-27T11:22:24.977Z",
                "updatedAt": "2022-07-15T08:21:51.508Z",
                "publishedAt": "2022-05-27T11:22:55.082Z",
                "Ordering": null,
                "Image": {
                    "data": [
                        {
                            "id": 2,
                            "attributes": {
                                "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Rectangle_316_6cb6ee2ad8.png",
                                "name": "Rectangle 316.png"
                            }
                        },
                        {
                            "id": 136,
                            "attributes": {
                                "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Microsoft_Teams_image_8_60849d7567.png",
                                "name": "MicrosoftTeams-image (8).png"
                            }
                        }
                    ]
                },
                "section_contents": {
                    "data": []
                }
            }
        },
        {
            "id": 12,
            "attributes": {
                "Title": "Grow your business with us",
                "Description": null,
                "Url": null,
                "Page": "Products",
                "createdAt": "2022-06-09T09:15:25.583Z",
                "updatedAt": "2022-06-24T07:30:16.235Z",
                "publishedAt": "2022-06-09T09:31:01.823Z",
                "Ordering": "1",
                "Image": {
                    "data": null
                },
                "section_contents": {
                    "data": [
                        {
                            "id": 12,
                            "attributes": {
                                "Title": "Multiuser support for a larger workforce",
                                "Url": null,
                                "Description": null,
                                "ordering": 1,
                                "createdAt": "2022-06-09T09:17:56.034Z",
                                "updatedAt": "2022-06-24T07:35:49.046Z",
                                "publishedAt": "2022-06-09T09:20:17.446Z",
                                "SubDescription": null,
                                "SubTitle": null,
                                "SectionImage": {
                                    "data": {
                                        "id": 25,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Ic_User_efe8d2ea9d.png",
                                            "name": "IcUser.png"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 13,
                            "attributes": {
                                "Title": "Ingredient Management",
                                "Url": null,
                                "Description": "Ingredients are the building blocks of the Food and Beverage industry. Technical Data Sheets, Certifications, Food Safety Controls, Units of Measure, Costing, and Shelf-life are all basic attributes of ingredients.  We have integrated Supply Chain, Food Safety, Quality, Cost, Claims, and Certifications into our core Ingredient Management Module.  Sourcing is built-in; compliance is integrated with documents updated every 24 hours. No need to hunt for documents or contact suppliers for out-of-date certifications; all subscribers have access to this critical module which includes ingredients from thousands of suppliers for you to explore.\n ",
                                "ordering": 2,
                                "createdAt": "2022-06-09T09:20:45.889Z",
                                "updatedAt": "2022-07-10T22:31:10.446Z",
                                "publishedAt": "2022-06-09T09:21:47.749Z",
                                "SubDescription": null,
                                "SubTitle": null,
                                "SectionImage": {
                                    "data": {
                                        "id": 22,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Ic_Ingredients_6fe7dc3780.png",
                                            "name": "IcIngredients.png"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 14,
                            "attributes": {
                                "Title": "Formula Management",
                                "Url": null,
                                "Description": "Competing and conflicting priorities are constant.  Version Control, Supplier Changes, Nutritional Limits, Claims, and Costing Sensory Attributes are all easily managed and organized with the Formula Management Module.  Whether New Product Development, Formula Modification, Entry into a New Market our Formula Management Module easily helps you maintain control.  Combined with the power of our Ingredient Management Module, basic administration is always compliant and up-to-date.  Focus your time on the delivery of the next amazing food product and not the administration of documents.  Formulating for a new foreign market – no sweat, we have you covered with regional regulatory compliance and local languages, translation, and compliance are fully integrated with RegUI8.\n\n",
                                "ordering": 3,
                                "createdAt": "2022-06-09T09:22:22.462Z",
                                "updatedAt": "2022-07-12T05:26:24.399Z",
                                "publishedAt": "2022-06-09T09:22:25.619Z",
                                "SubDescription": null,
                                "SubTitle": null,
                                "SectionImage": {
                                    "data": {
                                        "id": 24,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Ic_Recipe_c433bf865f.png",
                                            "name": "IcRecipe.png"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 15,
                            "attributes": {
                                "Title": "Project Administration",
                                "Url": null,
                                "Description": null,
                                "ordering": 4,
                                "createdAt": "2022-06-09T09:22:47.227Z",
                                "updatedAt": "2022-06-24T07:53:19.718Z",
                                "publishedAt": "2022-06-09T09:22:51.315Z",
                                "SubDescription": null,
                                "SubTitle": null,
                                "SectionImage": {
                                    "data": {
                                        "id": 23,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Ic_Project_493f00c0d1.png",
                                            "name": "IcProject.png"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 16,
                            "attributes": {
                                "Title": "Hazard Analysis",
                                "Url": null,
                                "Description": "The Ingredient Management Module is central to everything in RegUI8.  Once an ingredient is uploaded our powerful Artificial Intelligence takes over to determine:\nWhat is the probability of certain hazards are\nWhat the severity of those hazards could be\nWhether these hazards have been mitigated by:\nThe supplier\nThe formulation\nThe process\n \nIf a hazard remains the system suggests technology that can fully mitigate the hazard or if a process needs to be modified for time or other criteria to achieve commercially acceptable and regulatory compliant risk mitigation.  The selected process and product are then validated by peer-reviewed scientific publications to provide evidentiary support to demonstrate the adequacy of preventive controls.",
                                "ordering": 5,
                                "createdAt": "2022-06-09T09:23:52.560Z",
                                "updatedAt": "2022-07-10T22:32:36.360Z",
                                "publishedAt": "2022-06-09T09:23:56.454Z",
                                "SubDescription": null,
                                "SubTitle": null,
                                "SectionImage": {
                                    "data": {
                                        "id": 20,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Ic_Bio_Hazard_e64560317d.png",
                                            "name": "IcBioHazard.png"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 17,
                            "attributes": {
                                "Title": "AI-based contact retrieval",
                                "Url": null,
                                "Description": "People move, businesses expand, and sometimes close. \nContact Databases are often limited to a single user and not centralized for the benefit of an organization. Contact databases are static and require manual inputs or they become stale. We have harnessed the power of AI to keep contacts accurate and up-to-date.\n \n§  We automatically merge contacts into a single central hub.\nFields such as Address, Postal Code, State, and Country are Automatically scraped and verified from the web.\nContact info for personnel is automatically updated daily by using state-of-the-art NLP algorithms and comparing it to social media and other locations on the web.\n ",
                                "ordering": 6,
                                "createdAt": "2022-06-09T09:25:19.990Z",
                                "updatedAt": "2022-07-10T22:35:09.412Z",
                                "publishedAt": "2022-06-09T09:25:23.803Z",
                                "SubDescription": null,
                                "SubTitle": null,
                                "SectionImage": {
                                    "data": {
                                        "id": 21,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Ic_Contact_e79ff2fc5a.png",
                                            "name": "IcContact.png"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 18,
                            "attributes": {
                                "Title": "Multi-lingual Report Generation",
                                "Url": "",
                                "Description": "Perhaps you need these same reports in a few of the 111 different languages we support.  Simply design the report, select the output languages and file format desired, and click!\n-Report Ready.\n-Informe listo.\n-Rapport prêt.\n-रिपोर्ट तैयार\n- تقرير جاهز\n -準備報告",
                                "ordering": 7,
                                "createdAt": "2022-06-09T09:25:58.885Z",
                                "updatedAt": "2022-07-10T22:36:53.311Z",
                                "publishedAt": "2022-06-09T09:28:48.410Z",
                                "SubDescription": null,
                                "SubTitle": null,
                                "SectionImage": {
                                    "data": {
                                        "id": 93,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Ic_Project_543f5d0374.png",
                                            "name": "IcProject.png"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 19,
                            "attributes": {
                                "Title": "Custom Report Generation",
                                "Url": null,
                                "Description": "Need a comprehensive specification with micro testing requirements? A cost analysis showing cost per unit and cost per serving. A development brief, an ingredient, or a packaging requisition?  Maybe something more complex like a turnover document set for your entire formulation with ALL supporting ingredient and supplier documents to support your Contract Manufacturer?  A Comprehensive Hazard Analysis illustrating the process block flow? Beverage conversions between gallons and pounds, liters and kilograms, pounds to kilograms or percentages? Never an issue with RegUI8, retrieval of data, formatted in easily understood common file formats is why we built RegUI8.",
                                "ordering": 8,
                                "createdAt": "2022-06-09T09:29:14.480Z",
                                "updatedAt": "2022-07-10T22:35:48.898Z",
                                "publishedAt": "2022-06-09T09:30:17.553Z",
                                "SubDescription": null,
                                "SubTitle": null,
                                "SectionImage": {
                                    "data": {
                                        "id": 94,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Ic_Project_1_9559bc4505.png",
                                            "name": "IcProject (1).png"
                                        }
                                    }
                                }
                            }
                        }
                    ]
                }
            }
        },
        {
            "id": 13,
            "attributes": {
                "Title": "Products Page Alternate Section",
                "Description": null,
                "Url": null,
                "Page": "Products",
                "createdAt": "2022-06-09T09:31:38.285Z",
                "updatedAt": "2022-06-23T06:00:39.669Z",
                "publishedAt": "2022-06-09T10:41:38.388Z",
                "Ordering": "2",
                "Image": {
                    "data": null
                },
                "section_contents": {
                    "data": [
                        {
                            "id": 20,
                            "attributes": {
                                "Title": "Multiuser Support For A Larger Workforce",
                                "Url": null,
                                "Description": "We understand that the work today happens in collaboration. Keeping this in mind, Regul8's has been particularly designed for active remote collaboration regardless of the geographical boundary. Further, Regul8 offers a robust multi-tenant architecture which supports a large workforce that work in tandem.",
                                "ordering": 1,
                                "createdAt": "2022-06-09T10:01:55.793Z",
                                "updatedAt": "2022-07-21T21:24:59.379Z",
                                "publishedAt": "2022-06-09T10:01:59.314Z",
                                "SubDescription": "We understand that the work today happens in collaboration. Keeping this in mind, Regul8 has been particularly designed for active remote collaboration regardless of the geographical boundary. Further, Regul8 offers a robust multi-tenant architecture which supports a large workforce that work in tandem.\n\n\n",
                                "SubTitle": null,
                                "SectionImage": {
                                    "data": {
                                        "id": 27,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/alternate1_439cee875d.png",
                                            "name": "alternate1.png"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 21,
                            "attributes": {
                                "Title": "Ingredient Management",
                                "Url": null,
                                "Description": "Gabriel is a Machine Learning and DevOps engineer at YOOBIC with an interest in bringing AI knowledge to everyone! His session will show how to cr Gabriel is a Machine Learning and DevOps engineer at YOOBIC with an interest in bringing AI knowledge to everyone! His session will show how to c",
                                "ordering": 2,
                                "createdAt": "2022-06-09T10:04:51.175Z",
                                "updatedAt": "2022-07-12T05:16:21.412Z",
                                "publishedAt": "2022-06-09T10:05:12.743Z",
                                "SubDescription": "Ingredients are the building blocks of the Food and Beverage industry. Technical Data Sheets, Certifications, Food Safety Controls, Units of Measure, Costing,, Shelf-life are all basic attributes of ingredients.  We have integrated Supply Chain, Food Safety, Quality, Cost, Claims and Certifications into our core Ingredient Management Module.  Sourcing is built-in; compliance is integrated with document updates every 24 hours. No need to hunt for documents or contact suppliers for out of date certifications; all subscribers have access to this critical module which includes ingredients from thousands of suppliers for you to explore.",
                                "SubTitle": null,
                                "SectionImage": {
                                    "data": {
                                        "id": 30,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/alternate2_e1de1a440b.png",
                                            "name": "alternate2.png"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 22,
                            "attributes": {
                                "Title": "Formula Management",
                                "Url": null,
                                "Description": "Gabriel is a Machine Learning and DevOps engineer at YOOBIC with an interest in bringing AI knowledge to everyone! His session will show how to cr Gabriel is a Machine Learning and DevOps engineer at YOOBIC with an interest in bringing AI knowledge to everyone! His session will show how to c",
                                "ordering": 3,
                                "createdAt": "2022-06-09T10:15:08.802Z",
                                "updatedAt": "2022-07-12T05:19:44.295Z",
                                "publishedAt": "2022-06-09T10:15:11.724Z",
                                "SubDescription": "Competing and conflicting priorities are constant.  Version Control, Supplier Changes, Nutritional Limits, Claims, Costing Sensory Attributes are all easily managed and organized with the Formula Management Module.  Whether New Product Development, Formula Modification, Entry to a New Market our Formula Management Module easily helps you maintain control.  Combined with the power of our Ingredient Management Module, basic administration is always compliant and up-to-date.  Focus your time on the delivery of the next amazing food product and not the administration of documents.  Formulating for a new foreign market – no sweat, we have you covered with regional regulatory compliance and local languages, translation and compliance are fully integrated with RegUI8.",
                                "SubTitle": null,
                                "SectionImage": {
                                    "data": {
                                        "id": 28,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/alternate3_e08880cc1d.png",
                                            "name": "alternate3.png"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 23,
                            "attributes": {
                                "Title": "Project Management",
                                "Url": null,
                                "Description": "Gabriel is a Machine Learning and DevOps engineer at YOOBIC with an interest in bringing AI knowledge to everyone! His session will show how to cr Gabriel is a Machine Learning and DevOps engineer at YOOBIC with an interest in bringing AI knowledge to everyone! His session will show how to c",
                                "ordering": 4,
                                "createdAt": "2022-06-09T10:15:48.497Z",
                                "updatedAt": "2022-07-12T05:50:17.161Z",
                                "publishedAt": "2022-06-09T10:16:07.966Z",
                                "SubDescription": "Coming Soon",
                                "SubTitle": null,
                                "SectionImage": {
                                    "data": {
                                        "id": 26,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/alternate4_b9d8b5a2be.png",
                                            "name": "alternate4.png"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 24,
                            "attributes": {
                                "Title": "Smart Hazard Analysis",
                                "Url": null,
                                "Description": "Gabriel is a Machine Learning and DevOps engineer at YOOBIC with an interest in bringing AI knowledge to everyone! His session will show how to cr",
                                "ordering": 5,
                                "createdAt": "2022-06-09T10:18:07.334Z",
                                "updatedAt": "2022-07-25T11:08:58.691Z",
                                "publishedAt": "2022-06-09T10:18:32.454Z",
                                "SubDescription": "The Ingredient Management Module is central to everything in RegUI8.  Once an ingredient is uploaded our powerful Artificial Intelligence takes over to determine :\n- What the probability of certain hazards are\n- What the severity of those hazards could be\n- Whether these hazards have been mitigated by :   \n                            o The supplier                                                                                                                     \n                            o The formulation                                                                                                                     \n                            o The process\n                       \nIf a hazard remains the system suggests technology that can fully mitigate the hazard or if a process needs to be modified for time or other criteria to achieve commercially acceptable and regulatory compliant risk mitigation.  The selected process and product are then validated by peer reviewed scientific publications to provide evidentiary support to demonstrate adequacy of preventive controls.\n",
                                "SubTitle": null,
                                "SectionImage": {
                                    "data": {
                                        "id": 29,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/alternate5_e6463268da.png",
                                            "name": "alternate5.png"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 25,
                            "attributes": {
                                "Title": "AI-based contact retrieval",
                                "Url": null,
                                "Description": "Gabriel is a Machine Learning and DevOps engineer at YOOBIC with an interest in bringing AI knowledge to everyone! His session will show how to \n",
                                "ordering": 6,
                                "createdAt": "2022-06-09T10:21:03.889Z",
                                "updatedAt": "2022-07-12T05:23:04.665Z",
                                "publishedAt": "2022-06-09T10:21:12.577Z",
                                "SubDescription": "People move, businesses expand and sometimes close.  \nContact Databases are often limited to a single user and not centralized for the benefit of an organization. Contact databases are static and require manual inputs or they become stale. We have harnessed the power of AI to keep contacts accurate and up-to-date. \n \n- We automatically merge contacts into a single central hub.\n- Fields such as Address, Postal Code, State and Country are Automatically scraped and verified from the web.\n- Contact info for personnel are automatically updated daily by using the state-of-the-art NLP algorithms and comparing to social media and other locations on the web..\n",
                                "SubTitle": null,
                                "SectionImage": {
                                    "data": {
                                        "id": 31,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/alternate6_4c6b2e8d48.png",
                                            "name": "alternate6.png"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 26,
                            "attributes": {
                                "Title": "Multi-lingual Report Generation",
                                "Url": null,
                                "Description": "abriel is a Machine Learning and DevOps engineer at YOOBIC with an interest in bringing AI knowledge to everyone! His session will show how to cr Gabriel is a Machine Learning and DevOps engineer at YOOBIC with an interest in bringing AI knowledge to everyone! His session will show how to cr",
                                "ordering": null,
                                "createdAt": "2022-06-09T10:22:27.304Z",
                                "updatedAt": "2022-07-12T05:25:41.407Z",
                                "publishedAt": "2022-06-09T10:22:50.833Z",
                                "SubDescription": "Perhaps you need these same reports in a few of the 111 different languages we support.  Simply design the report, select the output languages and file format desired , and click! \n- Report Ready.\n- Informe listo.\n- Rapport prêt.\n- रिपोर्ट तैयार\n- تقرير جاهز\n- 準備報告",
                                "SubTitle": null,
                                "SectionImage": {
                                    "data": {
                                        "id": 32,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/alternate7_16cd59e8f1.png",
                                            "name": "alternate7.png"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 27,
                            "attributes": {
                                "Title": "Custom Report Generation",
                                "Url": null,
                                "Description": "Gabriel is a Machine Learning and DevOps engineer at YOOBIC with an interest in bringing AI knowledge to everyone! His session will show how to cr Gabriel is a Machine Learning and DevOps engineer at YOOBIC with an interest in bringing AI knowledge to everyone! His session will show how to cr",
                                "ordering": 8,
                                "createdAt": "2022-06-09T10:23:37.223Z",
                                "updatedAt": "2022-07-20T22:48:52.448Z",
                                "publishedAt": "2022-06-09T10:27:35.057Z",
                                "SubDescription": "Need a comprehensive specification with micro testing requirements? A cost analysis showing cost per unit and cost per serving. A development brief, an ingredient or packaging requisition?  Maybe something more complex like a turnover document set for your entire formulation with ALL supporting ingredient and supplier documents to support your Contract Manufacturer?  A Comprehensive Hazard Analyses illustrating the process block flow? Beverage conversions between gallons and pounds, liters and kilograms, pounds tokilograms or percentages? Never an issue with RegUI8, retrieval of data, formatted in easily understood common file formats is why we built RegUI8.",
                                "SubTitle": null,
                                "SectionImage": {
                                    "data": {
                                        "id": 82,
                                        "attributes": {
                                            "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Frame_1605_25da489513.png",
                                            "name": "Frame 1605.png"
                                        }
                                    }
                                }
                            }
                        }
                    ]
                }
            }
        }
    ],
    "meta": {
        "pagination": {
            "page": 1,
            "pageSize": 25,
            "pageCount": 1,
            "total": 3
        }
    }
}