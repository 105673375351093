export const demoData = {
    "data": [
        {
            "id": 5,
            "attributes": {
                "Title": "Try ",
                "Description": "Click the button below to get started\n\n",
                "Url": "for free!",
                "Page": "Home,Company,Products,Blog,Tier,Contact,Demo,FAQ,Team",
                "createdAt": "2022-05-27T11:22:24.977Z",
                "updatedAt": "2022-07-15T08:21:51.508Z",
                "publishedAt": "2022-05-27T11:22:55.082Z",
                "Ordering": null,
                "Image": {
                    "data": [
                        {
                            "id": 2,
                            "attributes": {
                                "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Rectangle_316_6cb6ee2ad8.png",
                                "name": "Rectangle 316.png"
                            }
                        },
                        {
                            "id": 136,
                            "attributes": {
                                "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Microsoft_Teams_image_8_60849d7567.png",
                                "name": "MicrosoftTeams-image (8).png"
                            }
                        }
                    ]
                },
                "section_contents": {
                    "data": []
                }
            }
        },
        {
            "id": 25,
            "attributes": {
                "Title": "Take a look at our sizzle reel !",
                "Description": "",
                "Url": null,
                "Page": "Demo",
                "createdAt": "2022-06-13T09:09:17.430Z",
                "updatedAt": "2022-07-21T23:14:28.062Z",
                "publishedAt": "2022-06-13T09:09:20.586Z",
                "Ordering": "1",
                "Image": {
                    "data": [
                        {
                            "id": 66,
                            "attributes": {
                                "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/bg_demo_b719d117e6.jpg",
                                "name": "bg-demo.jpg"
                            }
                        },
                        {
                            "id": 148,
                            "attributes": {
                                "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Demo_Video_43a0566ac8.mp4",
                                "name": "DemoVideo.mp4"
                            }
                        }
                    ]
                },
                "section_contents": {
                    "data": []
                }
            }
        }
    ],
    "meta": {
        "pagination": {
            "page": 1,
            "pageSize": 25,
            "pageCount": 1,
            "total": 2
        }
    }
}