import React, { useContext, useEffect, useState } from "react"
import StateContext from "../../../StateContext"

import { productData } from "../../../json/productData"
import CTA2 from "../../CTA2/CTA2"
import Page from "../Page"
import Alternate from "./Alternate/Alternate"
import GrowYourBusiness from "./GrowYourBusiness/GrowYourBusiness"
function ProductComponent() {
  const appState = useContext(StateContext)
  const [loading, setLoading] = useState(true)
  const [grow, setGrow] = useState()
  const [alternate, setAlternate] = useState()
  const [cta2, setCta2] = useState()

  useEffect(() => {
    productData.data?.map((item, i) => {
      switch (item.id) {
        case 12:
          setGrow(item)
          return
        case 13:
          setAlternate(item)
          return
        case 5:
          setCta2(item)
          return
        default:
          return
      }
    })
  }, [])

  // if (loading) {
  //   return <Loader />
  // }

  return (
    <main className="page-product">
      <Page title="Products">
        <GrowYourBusiness data={grow} />
        <Alternate data={alternate} />
        <CTA2 data={cta2} />
      </Page>
    </main>
  )
}

export default ProductComponent
