export const faqData = {
    "data": [
        {
            "id": 5,
            "attributes": {
                "Title": "Try ",
                "Description": "Click the button below to get started\n\n",
                "Url": "for free!",
                "Page": "Home,Company,Products,Blog,Tier,Contact,Demo,FAQ,Team",
                "createdAt": "2022-05-27T11:22:24.977Z",
                "updatedAt": "2022-07-15T08:21:51.508Z",
                "publishedAt": "2022-05-27T11:22:55.082Z",
                "Ordering": null,
                "Image": {
                    "data": [
                        {
                            "id": 2,
                            "attributes": {
                                "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Rectangle_316_6cb6ee2ad8.png",
                                "name": "Rectangle 316.png"
                            }
                        },
                        {
                            "id": 136,
                            "attributes": {
                                "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Microsoft_Teams_image_8_60849d7567.png",
                                "name": "MicrosoftTeams-image (8).png"
                            }
                        }
                    ]
                },
                "section_contents": {
                    "data": []
                }
            }
        },
        {
            "id": 26,
            "attributes": {
                "Title": "Help Center",
                "Description": "Having problems? We're here to help\n",
                "Url": null,
                "Page": "FAQ",
                "createdAt": "2022-06-13T09:13:28.413Z",
                "updatedAt": "2022-06-24T10:42:44.244Z",
                "publishedAt": "2022-06-13T09:13:31.487Z",
                "Ordering": "1",
                "Image": {
                    "data": null
                },
                "section_contents": {
                    "data": []
                }
            }
        },
        {
            "id": 27,
            "attributes": {
                "Title": "Question and Answer",
                "Description": null,
                "Url": null,
                "Page": "FAQ",
                "createdAt": "2022-06-13T09:38:43.457Z",
                "updatedAt": "2022-06-30T05:34:53.289Z",
                "publishedAt": "2022-06-13T09:38:46.797Z",
                "Ordering": "2",
                "Image": {
                    "data": null
                },
                "section_contents": {
                    "data": [
                        {
                            "id": 42,
                            "attributes": {
                                "Title": "How does D&H utilize AI to ensure compliance with FSMA? ",
                                "Url": null,
                                "Description": "D&H’s database, Regulate, utilizes the power of AI to pull sources to ensure that products are FSMA compliant. This database automatically sources documentation from reputable sources to analyze and support product developers with their recipe and process development.  Through this automated process, D&H utilizes its AI to build a foundation, from ingredient verification to process hazard analysis, that is constantly updated to ensure FSMA compliance. With each ingredient and recipe entered, the AI learns to designate classifications that are the basis of regulatory compliance.\n\n",
                                "ordering": 1,
                                "createdAt": "2022-06-13T09:39:38.446Z",
                                "updatedAt": "2022-06-23T22:55:07.170Z",
                                "publishedAt": "2022-06-13T09:39:41.648Z",
                                "SubDescription": "How does D&H utilize AI to ensure compliance with FSMA? \nD&H’s database, Regulate, utilizes the power of AI to pull sources to ensure that products are FSMA compliant. This database automatically sources documentation from reputable sources to analyze and support product developers with their recipe and process development.  Through this automated process, D&H utilizes its AI to build a foundation, from ingredient verification to process hazard analysis, that is constantly updated to ensure FSMA compliance. With each ingredient and recipe entered, the AI learns to designate classifications that are the basis of regulatory compliance.\n\n",
                                "SubTitle": null,
                                "SectionImage": {
                                    "data": null
                                }
                            }
                        },
                        {
                            "id": 43,
                            "attributes": {
                                "Title": "What was the inspiration for incorporating AI? ",
                                "Url": null,
                                "Description": "The inspiration for incorporating AI was first sparked when Mark stumbled upon “The Flavor Matrix” by Briscone & Parkhurst. Upon reading this book, there was an underlying concept, that as far-fetched as ingredients seemed, there was a common attribute that allowed for data generation. This data that was generated resulted in the ability to create unique and niche flavor pairings by finding the commonality amongst distinct foods. Using a similar ideology, D&H created Regulate, which pulls data amongst various ingredients and recipes to ultimately establish a commonality. Once a common attribute is extrapolated from the ingredients and recipes, the database utilizes this information to generate tools that can be applied to the food industry that allow for simpler and more standardized evaluations of otherwise subjective processes. ",
                                "ordering": 2,
                                "createdAt": "2022-06-13T09:40:10.226Z",
                                "updatedAt": "2022-06-23T22:42:47.384Z",
                                "publishedAt": "2022-06-13T09:40:13.709Z",
                                "SubDescription": "",
                                "SubTitle": null,
                                "SectionImage": {
                                    "data": null
                                }
                            }
                        }
                    ]
                }
            }
        },
        {
            "id": 28,
            "attributes": {
                "Title": "FAQs",
                "Description": "Gabriel is a Machine Learning and DevOps engineer at YOOBIC with an interest in bringing AI knowledge to everyone! His session will show how to cr Gabriel is a Machine Learning and DevOps engineer at YOOBIC",
                "Url": null,
                "Page": "FAQ",
                "createdAt": "2022-06-13T09:46:31.877Z",
                "updatedAt": "2022-06-24T11:15:51.429Z",
                "publishedAt": "2022-06-13T09:46:36.160Z",
                "Ordering": "3",
                "Image": {
                    "data": null
                },
                "section_contents": {
                    "data": []
                }
            }
        }
    ],
    "meta": {
        "pagination": {
            "page": 1,
            "pageSize": 25,
            "pageCount": 1,
            "total": 4
        }
    }
}