import { BrowserRouter, Switch, Route } from "react-router-dom";
//COMPONENTS
import Home from "./Pages/Home";
import Nav from "./Components/Nav/Nav";
import Footer from "./Components/Footer/Footer";
import Company from "./Pages/Company";
import Career from "./Pages/Career";
import CareerDetails from "./Pages/CareerDetails";
import Contact from "./Pages/Contact";
import Demo from "./Pages/Demo";
import Pricing from "./Pages/Pricing";
import OurTeam from "./Components/Pages/Company/OurTeam";
import FAQ from "./Pages/FAQ";
import Blogs from "./Pages/Blogs";
import BlogDetail from "./Components/Pages/Blogs/BlogDetail";
import Product from "./Pages/Product";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Terms from "./Pages/Terms";
import StateContext from "./StateContext";
import { useImmerReducer } from "use-immer";
import DispatchContext from "./DispatchContext";
import CookiePopup from "./Components/CookiePolicy/CookiePopup";
import { useEffect } from "react";
import { useState } from "react";
import CookiesJS from "js-cookie";
import ErrorPage from "./Components/Pages/ErrorPage";

function App() {
  const initialState = {
    cookieSettings: null,
    measureWebsiteUseCookie: true,
    helpMarketingCookie: true,
  };

  const [showCookiePolicy, setShowCookiePolicy] = useState(false);

  function ourReducer(draft, action) {
    switch (action.type) {
      case "FETCH_HOMEPAGE_SUCCESS":
        draft.homepage = action.value;
        return;
      case "FETCH_COMPANY_PAGE_SUCCESS":
        draft.companyPage = action.value;
        return;
      case "FETCH_PRODUCTS_PAGE_SUCCESS":
        draft.productsPage = action.value;
        return;
      case "FETCH_BLOG_PAGE_SUCCESS":
        draft.blogPage = action.value;
        return;
      case "FETCH_TIER_PAGE_SUCCESS":
        draft.tierPage = action.value;
        return;
      case "FETCH_CAREER_PAGE_SUCCESS":
        draft.careerPage = action.value;
        return;
      case "FETCH_FAQ_PAGE_SUCCESS":
        draft.faqPage = action.value;
        return;
      case "FETCH_CONTACT_PAGE_SUCCESS":
        draft.contactPage = action.value;
        return;
      case "FETCH_DEMO_PAGE_SUCCESS":
        draft.demoPage = action.value;
        return;
      case "FETCH_JOB-LISTS_SUCCESS":
        draft.jobList = action.value;
        return;
      case "FETCH_fOOTER_SUCCESS":
        draft.footer = action.value;
        return;
      case "SHOW_COOKIE_MODAL":
        draft.showCookieModal = action.value;
        return;
      case "SET_COOKIE_SETTING":
        if (action.cateogry == "measureWebisteUse") {
          draft.measureWebsiteUseCookie = action.value;
        } else if (action.cateogry == "helpMarketing") {
          draft.helpMarketingCookie = action.value;
        }
        return;
      case "SET_COOKIE_ACCEPTED":
        draft.setCookie = true;
        return;
      case "FETCH_COOKIEPOLICY_SUCCESS":
        draft.cookiePolicy = action.value;
        return;
      case "SET_COOKIE_PREFERENCE":
        draft.setCookie = true;
        return;
      case "REJECT_COOKIE_PREFERENCE":
        draft.setCookie = true;
        draft.measureWebsiteUseCookie = false;
        return;
      default:
        return;
    }
  }

  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  useEffect(() => {
    if (CookiesJS.get("ALLACCEPTEDCOOKIES") === "fasle") {
      setShowCookiePolicy(true);
      CookiesJS.set("ALLACCEPTEDCOOKIES", "false");
    } else if (CookiesJS.get("ALLACCEPTEDCOOKIES") === "true") {
      setShowCookiePolicy(false);
    } else {
      CookiesJS.set("ALLACCEPTEDCOOKIES", "true");
      setTimeout(() => {
        setShowCookiePolicy(true);
      }, 6000);
    }
  }, []);

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        <BrowserRouter>
          <Nav />
          <main>
            <Switch>
              <Route path="/" exact>
                <Home />
              </Route>
              <Route path="/company" exact>
                <Company />
              </Route>
              <Route path="/company/ourTeam" exact>
                <OurTeam />
              </Route>
              <Route path="/career" exact>
                <Career />
              </Route>
              <Route path="/career/:id" exact>
                <CareerDetails />
              </Route>
              <Route path="/contact" exact>
                <Contact />
              </Route>
              <Route path="/demo" exact>
                <Demo />
              </Route>
              <Route path="/tier" exact>
                <Pricing />
              </Route>
              <Route path="/faq" exact>
                <FAQ />
              </Route>
              <Route path="/blogs" exact>
                <Blogs />
              </Route>
              <Route path="/blogs/:id" exact>
                <BlogDetail />
              </Route>
              <Route path="/products" exact>
                <Product />
              </Route>
              <Route path="/privacy-policy" exact>
                <PrivacyPolicy />
              </Route>
              <Route path="/terms" exact>
                <Terms />
              </Route>
              <Route path="*">
                <ErrorPage />
              </Route>
            </Switch>
          </main>
          {showCookiePolicy && (
            <CookiePopup
              showCookiePolicy={showCookiePolicy}
              setShowCookiePolicy={setShowCookiePolicy}
            />
          )}
          <Footer />
        </BrowserRouter>
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
}

export default App;
