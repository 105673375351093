import React, { useEffect, useState } from "react"
import { companyData } from "../../../json/companyData"
import CTA2 from "../../CTA2/CTA2"
import Page from "../Page"
import GetToKnowUs from "./GetToKnowUs/GetToKnowUs"
import Hero from "./Hero/Hero"
import OurExpertise from "./OurExpertise/OurExpertise"
import WhoWeAre from "./WhoWeAre/WhoWeAre"
function CompanyComponent() {
  const [heroData, setHeroData] = useState()
  const [whoWeAre, setWhoWeAre] = useState()
  const [ourExpertise, setOurExpertise] = useState()
  const [getToKnowUs, setGetToKnowUs] = useState()
  const [cta2, setCta2] = useState()

  useEffect(() => {
    companyData.data?.map((item, i) => {
      switch (item.id) {
        case 7:
          setHeroData(item)
          return
        case 8:
          setWhoWeAre(item)
          return
        case 10:
          setOurExpertise(item)
          return
        case 11:
          setGetToKnowUs(item)
          return
        case 5:
          setCta2(item)
          return
        default:
          return
      }
    })
  }, [])


  return (
    <main className="company-page">
      <Page title="Company">
        <Hero data={heroData} />
        <WhoWeAre data={whoWeAre} />
        <OurExpertise data={ourExpertise} />
        <GetToKnowUs data={getToKnowUs} />
        <CTA2 data={cta2} />
      </Page>
    </main>
  )
}

export default CompanyComponent
