export const homeData = {
  "data": [
    {
      "id": 1,
      "attributes": {
        "Title": "WHY D&H",
        "Description": "D&H was founded to build robust software leveraging Artificial Intelligence to provide efficient and accurate solutions to industries confronted with complex regulations. D&H Technologies focuses on the Food and Beverage industry worldwide and streamlines compliance documentation, usually taking weeks or months to prepare by highly proficient subject matter experts. D&H integrates food safety and regulatory compliance into the foundation of everyday business, leveraging the strength of the entire enterprise for quicker, more efficient, and accurate results.\n",
        "Url": null,
        "Page": "Home",
        "createdAt": "2022-05-27T11:03:59.483Z",
        "updatedAt": "2022-06-25T01:25:05.316Z",
        "publishedAt": "2022-05-27T11:04:19.528Z",
        "Ordering": "3",
        "Image": {
          "data": [
            {
              "id": 11,
              "attributes": {
                "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/why_e5a0c3de08.png",
                "name": "why.png"
              }
            }
          ]
        },
        "section_contents": {
          "data": []
        }
      }
    },
    {
      "id": 2,
      "attributes": {
        "Title": "Automate your business with Regulate",
        "Description": "By carefully understanding and analyzing your business needs we offer various automation solutions to scale your business and cut down costs",
        "Url": null,
        "Page": "Home",
        "createdAt": "2022-05-27T11:05:14.433Z",
        "updatedAt": "2023-07-31T08:39:35.445Z",
        "publishedAt": "2022-05-27T11:05:35.744Z",
        "Ordering": "5",
        "Image": {
          "data": [
            {
              "id": 12,
              "attributes": {
                "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/automation_171d2666b1.gif",
                "name": "automation.gif"
              }
            }
          ]
        },
        "section_contents": {
          "data": []
        }
      }
    },
    {
      "id": 3,
      "attributes": {
        "Title": " How D&H can help accelerate your business needs",
        "Description": "D&H has experienced engineers and AI specialized research scientists who create custom automation to accelerate your business needs. Whether it be test based automation, tedious manual data automation or smart automation that needs machine learning , we have it all. Talk to us to create your own custom automation plan to start accelerating your business needs.",
        "Url": null,
        "Page": "Home",
        "createdAt": "2022-05-27T11:06:13.096Z",
        "updatedAt": "2022-07-19T05:28:42.787Z",
        "publishedAt": "2022-05-27T11:06:21.901Z",
        "Ordering": "6",
        "Image": {
          "data": [
            {
              "id": 81,
              "attributes": {
                "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/regul8compare_af7d156d2e.gif",
                "name": "regul8compare.gif"
              }
            }
          ]
        },
        "section_contents": {
          "data": []
        }
      }
    },
    {
      "id": 4,
      "attributes": {
        "Title": "Check out the power of our AI",
        "Description": "D&H uses Natural Language Processing (NLP) architecture to harness the power of Artificial Intelligence. Our algorithms are based upon thousands of hours of research from both industry and academia combined with the heuristic of domain experts. As such, our products exponentially cut down human errors and manual workflows resulting in an ideal symbiosis that every business desires – accuracy and scale.\n",
        "Url": null,
        "Page": "Home",
        "createdAt": "2022-05-27T11:19:16.871Z",
        "updatedAt": "2022-07-04T00:16:24.033Z",
        "publishedAt": "2022-06-08T04:46:48.221Z",
        "Ordering": "4",
        "Image": {
          "data": [
            {
              "id": 141,
              "attributes": {
                "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/dnhhomegif_ddaab22354.gif",
                "name": "dnhhomegif.gif"
              }
            }
          ]
        },
        "section_contents": {
          "data": []
        }
      }
    },
    {
      "id": 5,
      "attributes": {
        "Title": "Try ",
        "Description": "Click the button below to get started\n\n",
        "Url": "for free!",
        "Page": "Home,Company,Products,Blog,Tier,Contact,Demo,FAQ,Team",
        "createdAt": "2022-05-27T11:22:24.977Z",
        "updatedAt": "2022-07-15T08:21:51.508Z",
        "publishedAt": "2022-05-27T11:22:55.082Z",
        "Ordering": null,
        "Image": {
          "data": [
            {
              "id": 2,
              "attributes": {
                "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Rectangle_316_6cb6ee2ad8.png",
                "name": "Rectangle 316.png"
              }
            },
            {
              "id": 136,
              "attributes": {
                "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Microsoft_Teams_image_8_60849d7567.png",
                "name": "MicrosoftTeams-image (8).png"
              }
            }
          ]
        },
        "section_contents": {
          "data": []
        }
      }
    },
    {
      "id": 6,
      "attributes": {
        "Title": "",
        "Description": " With Regulate",
        "Url": null,
        "Page": "Home",
        "createdAt": "2022-05-27T11:24:42.295Z",
        "updatedAt": "2023-07-31T08:44:36.922Z",
        "publishedAt": "2022-07-15T22:22:21.589Z",
        "Ordering": "1",
        "Image": {
          "data": [
            {
              "id": 4,
              "attributes": {
                "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Frame_923_f674090d9e.png",
                "name": "Heroimage.png"
              }
            }
          ]
        },
        "section_contents": {
          "data": [
            {
              "id": 1,
              "attributes": {
                "Title": "Automate cumbersome workflows.",
                "Url": null,
                "Description": null,
                "ordering": 1,
                "createdAt": "2022-06-08T04:40:42.884Z",
                "updatedAt": "2022-07-05T02:17:17.170Z",
                "publishedAt": "2022-06-08T04:40:57.112Z",
                "SubDescription": null,
                "SubTitle": null,
                "SectionImage": {
                  "data": null
                }
              }
            },
            {
              "id": 2,
              "attributes": {
                "Title": "Automate manual processes.",
                "Url": null,
                "Description": null,
                "ordering": 2,
                "createdAt": "2022-06-08T04:41:08.686Z",
                "updatedAt": "2022-07-05T02:17:30.741Z",
                "publishedAt": "2022-06-08T04:41:13.118Z",
                "SubDescription": null,
                "SubTitle": null,
                "SectionImage": {
                  "data": null
                }
              }
            },
            {
              "id": 83,
              "attributes": {
                "Title": "Harness the power of Artificial intelligence",
                "Url": null,
                "Description": null,
                "ordering": 4,
                "createdAt": "2022-07-05T02:15:45.263Z",
                "updatedAt": "2022-07-05T02:15:50.069Z",
                "publishedAt": "2022-07-05T02:15:49.996Z",
                "SubDescription": null,
                "SubTitle": null,
                "SectionImage": {
                  "data": null
                }
              }
            },
            {
              "id": 84,
              "attributes": {
                "Title": "Architecture that scales",
                "Url": null,
                "Description": null,
                "ordering": 5,
                "createdAt": "2022-07-05T02:16:12.247Z",
                "updatedAt": "2022-07-05T02:24:28.575Z",
                "publishedAt": "2022-07-05T02:24:28.500Z",
                "SubDescription": null,
                "SubTitle": null,
                "SectionImage": {
                  "data": null
                }
              }
            }
          ]
        }
      }
    },
    {
      "id": 9,
      "attributes": {
        "Title": "Second Sections ",
        "Description": null,
        "Url": "",
        "Page": "Home",
        "createdAt": "2022-06-08T05:15:43.188Z",
        "updatedAt": "2022-06-08T11:03:21.682Z",
        "publishedAt": "2022-06-08T05:19:59.044Z",
        "Ordering": "2",
        "Image": {
          "data": null
        },
        "section_contents": {
          "data": [
            {
              "id": 3,
              "attributes": {
                "Title": "Request a Demo",
                "Url": "/demo",
                "Description": null,
                "ordering": null,
                "createdAt": "2022-06-08T05:16:50.293Z",
                "updatedAt": "2022-06-16T02:02:35.630Z",
                "publishedAt": "2022-06-08T05:16:54.684Z",
                "SubDescription": null,
                "SubTitle": null,
                "SectionImage": {
                  "data": {
                    "id": 8,
                    "attributes": {
                      "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Group_599_8daba8307a.png",
                      "name": "Group 599.png"
                    }
                  }
                }
              }
            },
            {
              "id": 4,
              "attributes": {
                "Title": "Contact Sales",
                "Url": "/contact",
                "Description": null,
                "ordering": null,
                "createdAt": "2022-06-08T05:17:36.257Z",
                "updatedAt": "2022-06-16T02:03:03.094Z",
                "publishedAt": "2022-06-08T05:17:39.813Z",
                "SubDescription": null,
                "SubTitle": null,
                "SectionImage": {
                  "data": {
                    "id": 9,
                    "attributes": {
                      "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Group_599_1_d4c32a7b6e.png",
                      "name": "Group 599 (1).png"
                    }
                  }
                }
              }
            },
            {
              "id": 5,
              "attributes": {
                "Title": "View Use Cases",
                "Url": "/products",
                "Description": null,
                "ordering": null,
                "createdAt": "2022-06-08T05:18:40.849Z",
                "updatedAt": "2022-06-16T02:03:52.588Z",
                "publishedAt": "2022-06-08T05:19:13.579Z",
                "SubDescription": null,
                "SubTitle": null,
                "SectionImage": {
                  "data": {
                    "id": 10,
                    "attributes": {
                      "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Group_599_2_443204baf8.png",
                      "name": "Group 599 (2).png"
                    }
                  }
                }
              }
            }
          ]
        }
      }
    }
  ],
  "meta": {
    "pagination": {
      "page": 1,
      "pageSize": 25,
      "pageCount": 1,
      "total": 7
    }
  }
}  