import React, { useContext, useEffect, useState } from "react"
import { demoData } from "../../../json/demoData"
import StateContext from "../../../StateContext"
import CTA2 from "../../CTA2/CTA2"
import Page from "../Page"
import SectionForm from "./SectionForm/SectionForm"
function DemoComponent() {
  const appState = useContext(StateContext)
  const [loading, setLoading] = useState(true)
  const [sectionForm, setSectionForm] = useState()
  const [cta2, setCta2] = useState()
  useEffect(() => {
    demoData.data?.map((item, i) => {
      switch (item.id) {
        case 25:
          setSectionForm(item)
          return
        case 5:
          setCta2(item)
          return
        default:
          return
      }
    })
  }, [])

  // if (loading) {
  //   return <Loader />
  // }
  return (
    <main className="page-demo">
      <Page title="Demo">
        <SectionForm data={sectionForm} />
        <CTA2 data={cta2} />
      </Page>
    </main>
  )
}

export default DemoComponent
