export const cookiesData = {
    "data": [
        {
            "id": 32,
            "attributes": {
                "Title": "Manage your cookie preferences",
                "Description": "We use cookies to enhance user experience and collect marketing information. By clicking “Accept“, you agree to our website's cookie use as described in our Privacy Policy. You can change your cookie settings at any time by clicking “Preferences.”",
                "Url": null,
                "Page": "CookiePolicy",
                "createdAt": "2022-07-01T09:17:24.969Z",
                "updatedAt": "2022-07-19T07:47:04.433Z",
                "publishedAt": "2022-07-01T09:22:04.722Z",
                "Ordering": "1",
                "Image": {
                    "data": null
                },
                "section_contents": {
                    "data": [
                        {
                            "id": 77,
                            "attributes": {
                                "Title": "Cookies that are strictly necessary",
                                "Url": null,
                                "Description": "These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These cookies do not store any personally identifiable information.",
                                "ordering": 1,
                                "createdAt": "2022-07-01T09:17:53.589Z",
                                "updatedAt": "2022-07-19T07:47:10.745Z",
                                "publishedAt": "2022-07-01T09:17:57.276Z",
                                "SubDescription": null,
                                "SubTitle": "0",
                                "SectionImage": {
                                    "data": null
                                }
                            }
                        },
                        {
                            "id": 79,
                            "attributes": {
                                "Title": "Cookies that help with our communications and marketing",
                                "Url": null,
                                "Description": "These cookies enable us to show marketing messages and first-party ads, and to measure the effectiveness of our social media presence. They may be used by us to group you in a targeting segment with people who have similar interests.",
                                "ordering": 3,
                                "createdAt": "2022-07-01T09:19:09.128Z",
                                "updatedAt": "2022-07-19T07:47:22.846Z",
                                "publishedAt": "2022-07-01T09:19:12.207Z",
                                "SubDescription": null,
                                "SubTitle": "0",
                                "SectionImage": {
                                    "data": null
                                }
                            }
                        },
                        {
                            "id": 87,
                            "attributes": {
                                "Title": "Main Cookies",
                                "Url": null,
                                "Description": null,
                                "ordering": null,
                                "createdAt": "2022-07-19T07:46:25.233Z",
                                "updatedAt": "2022-07-19T08:29:06.555Z",
                                "publishedAt": "2022-07-19T08:08:21.079Z",
                                "SubDescription": "We use cookies to enhance user experience and collect marketing information. By clicking “Accept“, you agree to our website's cookie use as described in our [Privacy Policy](privacy-policy). You can change your cookie settings at any time by clicking “Preferences.”",
                                "SubTitle": "1",
                                "SectionImage": {
                                    "data": null
                                }
                            }
                        }
                    ]
                }
            }
        }
    ],
    "meta": {
        "pagination": {
            "page": 1,
            "pageSize": 25,
            "pageCount": 1,
            "total": 1
        }
    }
}