import React, { useContext, useEffect, useState } from "react"
import { contactData } from "../../../json/contactData"
import StateContext from "../../../StateContext"
import CTA2 from "../../CTA2/CTA2"
import Page from "../Page"
import ContactUs from "./ContactUs/ContactUs"
import Hero from "./Hero/Hero"
function ContactComponent() {
  const appState = useContext(StateContext)
  const [loading, setLoading] = useState(true)
  const [hero, setHero] = useState()
  const [cta2, setCta2] = useState()

  useEffect(() => {
    contactData?.data?.map((item, i) => {
      switch (item.id) {
        case 24:
          setHero(item)
          return
        case 5:
          setCta2(item)
          return
        default:
          return
      }
    })

  }, [])

  // if (loading) {
  //   return <Loader />
  // }

  return (
    <main className="page-contact">
      <Page title="Contact Us">
        <Hero data={hero} />
        <ContactUs data={hero} />
        <CTA2 data={cta2} />
      </Page>
    </main>
  )
}

export default ContactComponent
