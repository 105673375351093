export const tierData = {
    "data": [
        {
            "id": 5,
            "attributes": {
                "Title": "Try ",
                "Description": "Click the button below to get started\n\n",
                "Url": "for free!",
                "Page": "Home,Company,Products,Blog,Tier,Contact,Demo,FAQ,Team",
                "createdAt": "2022-05-27T11:22:24.977Z",
                "updatedAt": "2022-07-15T08:21:51.508Z",
                "publishedAt": "2022-05-27T11:22:55.082Z",
                "Ordering": null,
                "Image": {
                    "data": [
                        {
                            "id": 2,
                            "attributes": {
                                "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Rectangle_316_6cb6ee2ad8.png",
                                "name": "Rectangle 316.png"
                            }
                        },
                        {
                            "id": 136,
                            "attributes": {
                                "url": "https://dnh-web-static.s3.us-west-1.amazonaws.com/Microsoft_Teams_image_8_60849d7567.png",
                                "name": "MicrosoftTeams-image (8).png"
                            }
                        }
                    ]
                },
                "section_contents": {
                    "data": []
                }
            }
        },
        {
            "id": 15,
            "attributes": {
                "Title": "Choose Your D&H Plan That Suits Your Needs",
                "Description": null,
                "Url": null,
                "Page": "Tier",
                "createdAt": "2022-06-10T04:06:44.376Z",
                "updatedAt": "2022-06-24T08:19:56.868Z",
                "publishedAt": "2022-06-10T04:06:48.915Z",
                "Ordering": null,
                "Image": {
                    "data": null
                },
                "section_contents": {
                    "data": [
                        {
                            "id": 28,
                            "attributes": {
                                "Title": "Basic",
                                "Url": "https://dnhtech.netlify.app/#/auth/sign-up",
                                "Description": "Sit amet consectetur adipiscing elit sed do eiusmod tem porincididunt ut labore et dolore magna aliqua. Quis psum suspendisse",
                                "ordering": 1,
                                "createdAt": "2022-06-10T05:16:08.998Z",
                                "updatedAt": "2022-06-24T08:17:47.865Z",
                                "publishedAt": "2022-06-10T10:05:35.062Z",
                                "SubDescription": "- Sit amet consectetur adipiscing do eius\n- Sit amet consectetur adipiscing do eius\n- Sit amet consectetur adipiscing do eius\n- Sit amet consectetur adipiscing do eius\n- Sit amet consectetur adipiscing do eius\n- Sit amet consectetur adipiscing do eius\n- Sit amet consectetur adipiscing do eius",
                                "SubTitle": "Tier-1",
                                "SectionImage": {
                                    "data": null
                                }
                            }
                        },
                        {
                            "id": 34,
                            "attributes": {
                                "Title": "Standard",
                                "Url": "https://dnhtech.netlify.app/#/auth/sign-up",
                                "Description": "Sit amet consectetur adipiscing elit sed do eiusmod tem porincididunt ut labore et dolore magna aliqua. Quis psum suspendisse",
                                "ordering": 2,
                                "createdAt": "2022-06-10T09:36:27.709Z",
                                "updatedAt": "2022-06-24T08:18:35.831Z",
                                "publishedAt": "2022-06-10T10:06:00.006Z",
                                "SubDescription": "- Sit amet consectetur adipiscing do eius\n- Sit amet consectetur adipiscing do eius\n- Sit amet consectetur adipiscing do eius\n- Sit amet consectetur adipiscing do eius\n- Sit amet consectetur adipiscing do eius\n- Sit amet consectetur adipiscing do eius\n- Sit amet consectetur adipiscing do eius\n- Sit amet consectetur adipiscing do eius\n- Sit amet consectetur adipiscing do eius\n",
                                "SubTitle": "Tier-2",
                                "SectionImage": {
                                    "data": null
                                }
                            }
                        },
                        {
                            "id": 35,
                            "attributes": {
                                "Title": "Premium",
                                "Url": "https://dnhtech.netlify.app/#/auth/sign-up",
                                "Description": "Sit amet consectetur adipiscing elit sed do eiusmod tem porincididunt ut labore et dolore magna aliqua. Quis psum suspendisse",
                                "ordering": 3,
                                "createdAt": "2022-06-10T09:37:50.905Z",
                                "updatedAt": "2022-06-15T09:46:44.451Z",
                                "publishedAt": "2022-06-10T10:06:21.130Z",
                                "SubDescription": "- Sit amet consectetur adipiscing do eius\n- Sit amet consectetur adipiscing do eius\n- Sit amet consectetur adipiscing do eius\n- Sit amet consectetur adipiscing do eius\n- Sit amet consectetur adipiscing do eius\n- Sit amet consectetur adipiscing do eiusvv\n- Sit amet consectetur adipiscing do eius\n- Sit amet consectetur adipiscing do eius\n- Sit amet consectetur adipiscing do eius\n- Sit amet consectetur adipiscing do eius\n- Sit amet consectetur adipiscing do eius\n- Sit amet consectetur adipiscing do eius\n- ",
                                "SubTitle": "Tier-2",
                                "SectionImage": {
                                    "data": null
                                }
                            }
                        }
                    ]
                }
            }
        }
    ],
    "meta": {
        "pagination": {
            "page": 1,
            "pageSize": 25,
            "pageCount": 1,
            "total": 2
        }
    }
}